import { unites } from 'friendswars.shared'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Icone from '@/components/blocs/Icone/Icone.vue'
import LienVillage from '@/components/blocs/LienVillage/LienVillage.vue'

export default {
	components: {
		BlocAction,
		Icone,
		LienVillage
	},
	data() {
		const unitesClone = Object.assign({}, unites)
		delete unitesClone.espions
		return {
			unites: unitesClone
		}
	},
	computed: {
		rapport() {
			return this.$store.state.rapportSelectionne
		}
    }
}