export default {
	props: {
		jeu: Boolean
	},
	computed: {
		url() {
			return this.jeu && this.$store.state.monde ? '/jeu' : '/'
		}
	},
    methods: {
		deconnexion() {
			localStorage.setItem('jeton', '')
			this.$store.state.utilisateur = null
			this.$store.state.jeton = null
			this.$store.state.monde = null
			this.$store.state.villages = null
			this.$store.state.village = null
			this.$store.state.rapports = null

			this.$router.push({
				name: 'accueil'
			})
		}
    }
}