import PageCreationMonde from '../components/pages/mondes/PageCreationMonde/PageCreationMonde.vue'
import PageRejoindreMonde from '../components/pages/mondes/PageRejoindreMonde/PageRejoindreMonde.vue'
import PageMondes from '../components/pages/mondes/PageMondes/PageMondes.vue'
import PageLobby from '../components/pages/mondes/PageLobby/PageLobby.vue'

export default {
	creation: {
		path: '/creation-monde',
		name: 'creation.monde',
		meta: {
			titre: 'Création d\'un monde'
		},
		component: PageCreationMonde
	},
	rejoindre: {
		path: '/rejoindre-monde',
		name: 'rejoindre.monde',
		meta: {
			titre: 'Rejoindre un monde'
		},
		component: PageRejoindreMonde
	},
	liste: {
		path: '/mondes',
		name: 'mondes',
		meta: {
			titre: 'Mondes'
		},
		component: PageMondes
	},
	lobby: {
		path: '/lobby',
		name: 'lobby',
		meta: {
			titre: 'Lobby'
		},
		component: PageLobby
	}
}