import Bouton from '@/components/blocs/Bouton/Bouton.vue'
import DetailsRapportAttaque from './DetailsRapportAttaque/DetailsRapportAttaque.vue'
import DetailsRapportEspionnage from './DetailsRapportEspionnage/DetailsRapportEspionnage.vue'
import DetailsRapportMouvementArmee from './DetailsRapportMouvementArmee/DetailsRapportMouvementArmee.vue'
import DetailsRapportSoutienAttaque from './DetailsRapportSoutienAttaque/DetailsRapportSoutienAttaque.vue'
import DetailsRapportInformation from './DetailsRapportInformation/DetailsRapportInformation.vue'

export default {
	components: {
		Bouton,
		DetailsRapportAttaque,
		DetailsRapportEspionnage,
		DetailsRapportMouvementArmee,
		DetailsRapportSoutienAttaque,
		DetailsRapportInformation
	},
	computed: {
		rapports() {
			return this.$store.state.rapports
		},
		rapportSelectionne() {
			return this.$store.state.rapportSelectionne
		},
		composantRapportSelectionne() {
			if (this.rapportSelectionne) {
				return 'details-rapport-' + this.rapportSelectionne.nom
			}
		},
		indexRapport() {
			return this.$store.state.rapports.indexOf(this.rapportSelectionne)
		}
	},
	methods: {
		rapportPrecedent() {
			this.$store.state.rapportSelectionne = this.$store.state.rapports[this.indexRapport - 1]
		},
		rapportSuivant() {
			this.$store.state.rapportSelectionne = this.$store.state.rapports[this.indexRapport + 1]
		},
		fermer() {
			this.$router.back()
		},
		marquerCommeVu() {
			this.rapportSelectionne.vu = true

			switch (this.rapportSelectionne.nom) {
				case 'attaque':
					this.$serveur.rapports.marquerRapportAttaqueCommeVu(this.rapportSelectionne.id)
					break;
				case 'espionnage':
					this.$serveur.rapports.marquerRapportEspionnageCommeVu(this.rapportSelectionne.id)
					break;
				case 'mouvement-armee':
					this.$serveur.rapports.marquerRapportMouvementArmeeCommeVu(this.rapportSelectionne.id)
					break;
				case 'soutien-attaque':
					this.$serveur.rapports.marquerRapportSoutienAttaqueCommeVu(this.rapportSelectionne.id)
					break;
				case 'information':
					this.$serveur.rapports.marquerRapportInformationCommeVu(this.rapportSelectionne.id)
				break;
			}
		}
	},
	watch: {
		rapportSelectionne() {
			this.$parent.$el.scrollTop = 0

			if (this.$parent.update) {
				this.$parent.update()
			}
			
			if (!this.rapportSelectionne.vu) {
				this.marquerCommeVu()
			}
		}
	},
	mounted() {
		if (!this.rapportSelectionne.vu) {
			this.marquerCommeVu()
		}
	},
	beforeCreate() {
		if (!this.$store.state.rapportSelectionne) {
			this.$router.back()
		}
	}
}