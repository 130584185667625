const creer = (donnees) => {
	return document.axios.post('cartes', donnees)
}

const modifier = (idCarte, donnees) => {
	return document.axios.put('cartes/' + idCarte, donnees)
}

const supprimer = (idCarte) => {
	return document.axios.delete('cartes/' + idCarte)
}

const listeJouable = () => {
	return document.axios.get('cartes/jouable')
}

const listeJoueur = () => {
	return document.axios.get('cartes/joueur')
}

const getCarteDuMonde = (idMonde) => {
	return document.axios.get('cartes/' + idMonde)
}

const choisirPosition = (idMonde, position) => {
	return document.axios.post('cartes/position/' + idMonde + '/' + position)
}

export default {
	creer,
	modifier,
	supprimer,
	listeJouable,
	listeJoueur,
	getCarteDuMonde,
	choisirPosition
}
