import { render, staticRenderFns } from "./PageCaserne.vue?vue&type=template&id=7089f19b&"
import script from "./PageCaserne.js?vue&type=script&lang=js&"
export * from "./PageCaserne.js?vue&type=script&lang=js&"
import style0 from "./PageCaserne.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports