import Vue from 'vue'
import Vuetify from 'vuetify'
import fr from 'vuetify/es5/locale/fr'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
	lang: {
		locales: {
			fr
		},
		current: 'fr'
	},
	icons: {
		iconfont: 'mdi'
	},
	theme: {
		dark: true,
		themes: {
			dark: {
				primary: '#8f5d2f',
				accent: '#503e34',
				secondary: '#E69739',
				error: '#A50000',
				success: '#427544',
				yellow: '#EAC359',
				// green: '#427544',
				// red: '#A23B33',
				// blue: '#3B79AB',
				// purple: '#823190',
				// orange: '#b9812d',
			}
		}
	}
})
