import { batiments } from 'friendswars.shared'
import Batiment from './Batiment.js'

export default class Marche extends Batiment {
	constructor(niveau) {
		super(batiments.marche, niveau)

		this.marchandsBase = batiments.marche.marchandsBase
		this.ratioMarchands = batiments.marche.ratioMarchands
	}

	getMarchands () {
		if (this.niveau == 0) return 0

		return this.calculerMontant(this.marchandsBase, this.ratioMarchands, this.niveau)
	}
}