import HautDePageInterface from '@/components/blocs/HautDePageInterface/HautDePageInterface.vue'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Carte from '@/components/blocs/Carte/Carte.vue'

export default {
	components: {
		HautDePageInterface,
		BlocAction,
        Carte
	},
	data() {
		return {
			cartes: null,
            afficherConfirmationSuppression: false,
            carteSupprimer: null
		}
	},
    methods: {
        getListe() {
            this.$serveur.cartes.listeJoueur().then(reponse => {
                this.cartes = reponse.data.cartes
                this.cartes.sort(Array.triNombre(c => c.nombreJoueurs))
            })
        },
        editer(carte) {
            this.$store.state.carte = carte
            this.$router.push('/editer-carte')
        },
        supprimer(carte) {
            this.carteSupprimer = carte
            this.afficherConfirmationSuppression = true
        },
        confirmerSupprimer() {
            this.$serveur.cartes.supprimer(this.carteSupprimer.id).then(() => {
                this.cartes.remove(this.carteSupprimer)
                this.carteSupprimer = null
                this.afficherConfirmationSuppression = false
            })
        }
    },
    mounted() {
        this.getListe()
        this.$store.state.carte = null
    }
}