import HautDePageInterface from '@/components/blocs/HautDePageInterface/HautDePageInterface.vue'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Avatar from '@/components/blocs/Avatar/Avatar.vue'
import SelectionAvatar from './SelectionAvatar/SelectionAvatar.vue'

export default {
	components: {
		HautDePageInterface,
		BlocAction,
		Avatar,
		SelectionAvatar
	},
	data() {
		return {
            afficherAvatarDialog: false,
			chargementEnvoi: false,
			emailEnvoye: false,
			couleurs: [
				'#F44336', // Rouge
				'#2A8AD6', // Bleu
				'#4CAF50', // Vert
				'#FFC107', // Jaune
				'#821B94', // Violet
				'#00796B', // Bleu canard
				'#E91E63', // Rose
				'#B71C1C', // Rouge foncé
				'#C0CA33', // Vert clair
				'#2943d1', // Bleu foncé
				'#C175B9', // Violet pale
				'#80DEEA', // Bleu Cyan
			]
		}
	},
	computed: {
		utilisateur() {
			return this.$store.state.utilisateur
		}
	},
	methods: {
		selectionAvatar(avatar) {
			this.afficherAvatarDialog = false
			const donnees = {
				avatar
			}
			this.$serveur.compte.selectionnerAvatar(donnees).then(() => {
				this.$store.state.utilisateur.avatar = avatar
			})
		},
		modifierCouleurDefaut(couleur) {
			const donnees = {
				couleur
			}
			this.$serveur.compte.modifierCouleurDefaut(donnees).then(() => {
				this.$store.state.utilisateur.couleurDefaut = couleur
			})
		},
		validationEmail() {
			this.chargementEnvoi = true
			this.$serveur.compte.envoiEmailValidation().then(() => {
				this.emailEnvoye = true
			}).finally(() => this.chargementEnvoi = false)
		},
		modifierParametreNotification(type) {
			this.$serveur.compte.modifierParametreNotification(type).then(reponse => {
				this.$store.state.utilisateur.notificationEmailNouveauMessage = reponse.data.notificationEmailNouveauMessage
				this.$store.state.utilisateur.notificationPushNouveauMessage = reponse.data.notificationPushNouveauMessage
				this.$store.state.utilisateur.notificationEmailNouveauTour = reponse.data.notificationEmailNouveauTour
				this.$store.state.utilisateur.notificationPushNouveauTour = reponse.data.notificationPushNouveauTour
			}) 
		}
	}
}