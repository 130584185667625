import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Bouton from '@/components/blocs/Bouton/Bouton.vue'

export default {
	components: {
		BlocAction,
		Bouton
	},
	data() {
		return {
			email: null,
			motDePasse: null,
			confirmation: null,
			montrerMotDePasse: false,
			montrerConfirmation: false,
			erreurs: {}
		}
	},
    props: {
        jeton: String
    },
	methods: {
		valider() {
			const donnees = {
				motDePasse: this.motDePasse,
                confirmation: this.confirmation
			}
			this.$serveur.authentification.reinitialisationMotDePasse(this.jeton, donnees).then(() => {
				this.erreurs = {}
				this.$router.replace({
					name: 'connexion'
				})
                this.$root.$children[0].ajouterNotification('Ton mot de passe a été modifié.', 'success')
			}, (erreur) => {
				if (erreur.response.status == 422) {
					this.erreurs = erreur.response.data.erreurs
				} else if (erreur.response.status == 400) {
                    this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
                    this.$router.replace({
                        name: 'accueil'
                    })
                }
			})
		}
	},
    mounted() {
		this.$serveur.authentification.recupererReinitialisationMotDePasse(this.jeton).then((reponse) => {
            this.email = reponse.data.utilisateur.email
        }, (erreur) => {
            if (erreur.response.status == 400) {
                this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				this.$router.replace({
					name: 'accueil'
				})
            }
        })
    }
}