export default class Utilisateur {
	constructor(contenu) {
		this.id = contenu.id
		this.nom = contenu.nom
		this.avatar = contenu.avatar
		this.couleur = contenu.couleur
		this.tourTermine = contenu.tourTermine
		this.arret = contenu.arret
		this.score = contenu.score
		this.couleurDefaut = contenu.couleurDefaut
		this.dateVerification = contenu.dateVerification
		this.idDernierEvenement = contenu.idDernierEvenement || 0
		this.equipe = null
		this.notificationPushNouveauMessage = contenu.notificationPushNouveauMessage
		this.notificationEmailNouveauMessage = contenu.notificationEmailNouveauMessage
		this.notificationPushNouveauTour = contenu.notificationPushNouveauTour
		this.notificationEmailNouveauTour = contenu.notificationEmailNouveauTour
	}

	get libelleArret () {
		switch (this.arret) {
			case 1: return 'Victoire'
			case 2: return 'Défaite'
			case 3: return 'Abandon'
			default: return '—'
		}
	}
}