import store from '@/store'

export default class RapportEspionnage {
	constructor(contenu) {
		this.id = contenu.id
		this.pourcentageReussite = contenu.pourcentageReussite
		this.utilisateurAttaquant = store.state.monde.utilisateurs.find(u => u.id == contenu.idUtilisateurAttaquant)
		this.utilisateurDefenseur = store.state.monde.utilisateurs.find(u => u.id == contenu.idUtilisateurDefenseur)
		this.villageAttaquant = store.state.villages.find(v => v.id == contenu.idVillageAttaquant)
		this.villageDefenseur = store.state.villages.find(v => v.id == contenu.idVillageDefenseur)
		this.couleurAttaquant = this.utilisateurAttaquant ? this.utilisateurAttaquant.couleur : 'grey'
		this.couleurDefenseur = this.utilisateurDefenseur ? this.utilisateurDefenseur.couleur : 'grey'
		this.tour = contenu.tour
		this.chance = contenu.chance
		this.precision = contenu.precision
		this.attaquant = contenu.idUtilisateurAttaquant == store.state.utilisateur.id
		this.vu = contenu.vu
		this.nom = 'espionnage'

		// Espions.
		this.espionsAttaquant = contenu.espionsAttaquant
		this.espionsPerdusAttaquant = contenu.espionsPerdusAttaquant
		this.espionsDefenseur = contenu.espionsDefenseur
		this.espionsPerdusDefenseur = contenu.espionsPerdusDefenseur
		
		// Unités.
		this.guerriers = contenu.guerriers
		this.epeistes = contenu.epeistes
		this.archers = contenu.archers
		this.cavaliers = contenu.cavaliers
		this.beliers = contenu.beliers
		this.catapultes = contenu.catapultes
        
		// Batiments.
		this.chateau = contenu.chateau
		this.entrepot = contenu.entrepot
		this.ferme = contenu.ferme
		this.camp = contenu.camp
		this.carriere = contenu.carriere
		this.mine = contenu.mine
		this.caserne = contenu.caserne
		this.muraille = contenu.muraille
		this.taverne = contenu.taverne
		this.marche = contenu.marche

		// Ressources.
		this.bois = contenu.bois
		this.argile = contenu.argile
		this.fer = contenu.fer
	}

	get iconePrincipale () {
        return 'espions'
	}

	get iconeSecondaire () {
		return this.attaquant ? 'aller' : 'bouclierEpee'
	}

	get titre () {
		return this.villageAttaquant.nom + ' espionne ' + this.villageDefenseur.nom + ' ' + this.villageDefenseur.position
	}
}