import store from '@/store'

export default class RapportMouvementArmee {
	constructor(contenu) {
		this.id = contenu.id
		this.utilisateurDepart = store.state.monde.utilisateurs.find(u => u.id == contenu.idUtilisateurDepart)
		this.utilisateurArrivee = store.state.monde.utilisateurs.find(u => u.id == contenu.idUtilisateurArrivee)
		this.utilisateurOrdre = store.state.monde.utilisateurs.find(u => u.id == contenu.idUtilisateurOrdre)
		this.villageDepart = store.state.villages.find(v => v.id == contenu.idVillageDepart)
		this.villageArrivee = store.state.villages.find(v => v.id == contenu.idVillageArrivee)
		this.couleurDepart = this.utilisateurDepart ? this.utilisateurDepart.couleur : 'grey'
		this.couleurArrivee = this.utilisateurArrivee ? this.utilisateurArrivee.couleur : 'grey'
		this.tour = contenu.tour
		this.renvoi = contenu.renvoi
		this.vu = contenu.vu
		this.renvoyeParSoutenu = this.renvoi ? contenu.idUtilisateurArrivee == contenu.idUtilisateurOrdre : false
		this.nom = 'mouvement-armee'

		// Unités.
		this.guerriers = contenu.guerriers
		this.epeistes = contenu.epeistes
		this.archers = contenu.archers
		this.cavaliers = contenu.cavaliers
		this.beliers = contenu.beliers
		this.catapultes = contenu.catapultes
	}

	get iconePrincipale () {
		return 'soutien'
	}

	get iconeSecondaire () {
		return this.renvoi ? 'retour' : 'aller'
	}

	get titre () {
		if (this.renvoi) {
			if (this.renvoyeParSoutenu) {
				return this.villageArrivee.nom + ' ' + this.villageArrivee.position + ' a renvoyé le soutien de ' + this.villageDepart.nom + ' ' + this.villageDepart.position
			} else {
				return this.villageDepart.nom + ' ' + this.villageDepart.position + ' a renvoyé son soutien de ' + this.villageArrivee.nom + ' ' + this.villageArrivee.position
			}
		} else {
			return this.villageDepart.nom + ' ' + this.villageDepart.position + ' soutien ' + this.villageArrivee.nom + ' ' + this.villageArrivee.position
		}
	}
}