import { unites } from 'friendswars.shared'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Icone from '@/components/blocs/Icone/Icone.vue'
import AfficheurPrix from '@/components/blocs/AfficheurPrix/AfficheurPrix.vue'
import BoutonAmeliorerBatiment from '@/components/blocs/BoutonAmeliorerBatiment/BoutonAmeliorerBatiment.vue'
import LienVillage from '@/components/blocs/LienVillage/LienVillage.vue'

export default {
	components: {
		BlocAction,
		Icone,
		AfficheurPrix,
		BoutonAmeliorerBatiment,
		LienVillage
	},
	data () {
		return {
			unitesSansEspion: Object.fromEntries(Object.entries(unites).filter(([nom]) => nom != 'espions')),
			afficherDialogAttaques: false,
			afficherDialogSoutiens: false,
			afficherDialogEspionnages: false,
			afficherDialogEchanges: false,
			afficherDialogMouvementsRessources: false,
			publicPath: process.env.BASE_URL
		}
	},
	computed: {
		village() {
			return this.$store.state.village
		},
		tourTermine() {
			return this.$store.state.tourTermine
		},
		niveauxFinaux() {
			const niveaux = {}

			Object.entries(this.village.batiments).forEach(([nomBatiment, batiment]) => {
				niveaux[nomBatiment] = batiment.niveau
			})

			this.village.constructions
				.forEach(action => {
					niveaux[action.nomBatiment]++
				})

			return niveaux
		},
		constructions() {
			return this.village.constructions.map(construction => {
				construction.batiment = this.village[construction.nomBatiment]
				return construction
			})
		},
		recrutements() {
			return this.village.recrutements.map(recrutement => {
				recrutement.unite = unites[recrutement.nomUnite]
				return recrutement
			})
		},
		attaquesVillage() {
			if (this.$store.state.ordres) {
				return this.$store.state.ordres.attaques.interne.aller.filter(attaque =>
					attaque.villageAttaquant.id == this.village.id &&
					attaque.tourEnvoi == this.$store.state.monde.tour
				)
			}
			return []
		},
		soutiensVillage() {
			if (this.$store.state.ordres) {
				return this.$store.state.ordres.mouvementsArmees.interne.filter(mouvement =>
					mouvement.villageDepart.id == this.village.id &&
					mouvement.type == 1
				)
			}
			return []
		},
		espionnagesVillage() {
			if (this.$store.state.ordres) {
				return this.$store.state.ordres.espionnages.filter(espionnage =>
					espionnage.villageAttaquant.id == this.village.id &&
					espionnage.tourDepart == this.$store.state.monde.tour
				)
			}
			return []
		},
		echangesVillage() {
			if (this.$store.state.ordres) {
				return this.$store.state.ordres.echangesRessources.filter(echange => echange.village.id == this.village.id)
			}
			return []
		},
		mouvementsRessourcesVillage() {
			if (this.$store.state.ordres) {
				return this.$store.state.ordres.mouvementsRessources.filter(mouvementRessources =>
					mouvementRessources.villageDepart.id == this.village.id &&
					mouvementRessources.tourDepart == this.$store.state.monde.tour
				)
			}
			return []
		}
	},
	methods: {
		afficherBatiment(nomBatiment) {
			this.$router.push({
				name: 'jeu.' + nomBatiment
			})
		},
		annulerConstruction(construction) {
			this.$serveur.actions.annulerConstruction(construction.id).then(reponse => {
				this.$store.commit('actualiserVillage', reponse.data.village)
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		annulerRecrutement(recrutement) {
			this.$serveur.actions.annulerRecrutement(recrutement.id).then(reponse => {
				this.$store.commit('actualiserVillage', reponse.data.village)
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		annulerAchat(achat) {
			this.$serveur.actions.annulerAchat(achat.id).then(reponse => {
				this.$store.commit('actualiserVillage', reponse.data.village)
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		annulerAttaque(attaque) {
			this.afficherDialogAttaques = false
			this.$serveur.ordres.annulerAttaque(attaque.id).then(reponse => {
				this.$store.commit('actualiserVillage', reponse.data.village)
				this.$store.commit('actualiserOrdres', reponse.data.ordres)
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		annulerSoutien(soutien) {
			this.afficherDialogSoutiens = false
			this.$serveur.ordres.annulerSoutien(soutien.id).then(reponse => {
				this.$store.commit('actualiserVillage', reponse.data.village)
				this.$store.commit('actualiserOrdres', reponse.data.ordres)
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		annulerEspionnage(espionnage) {
			this.afficherDialogEspionnages = false
			this.$serveur.ordres.annulerEspionnage(espionnage.id).then(reponse => {
				this.$store.commit('actualiserVillage', reponse.data.village)
				this.$store.commit('actualiserOrdres', reponse.data.ordres)
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		annulerEchange(echange) {
			this.afficherDialogEchanges = false
			this.$serveur.ordres.annulerEchange(echange.id).then(reponse => {
				this.$store.commit('actualiserVillage', reponse.data.village)
				this.$store.commit('actualiserOrdres', reponse.data.ordres)
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		annulerApprovisionnement(mouvementRessources) {
			this.afficherDialogMouvementsRessources = false
			this.$serveur.ordres.annulerApprovisionnement(mouvementRessources.id).then(reponse => {
				this.$store.commit('actualiserVillage', reponse.data.village)
				this.$store.commit('actualiserOrdres', reponse.data.ordres)
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		annulerTravailleurs() {
			this.$serveur.actions.annulerTravailleurs(this.village.id).then(reponse => {
				this.$store.commit('actualiserVillage', reponse.data.village)
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		}
	},
	beforeCreate() {
		if (this.$store.state.monde.utilisateur.arret) {
			this.$router.replace({
				name: 'jeu'
			})
		}
	}
}