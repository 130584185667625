import Vue from 'vue'
import Router from 'vue-router'
import accueil from './accueil'
import authentification from './authentification'
import compte from './compte'
import mondes from './mondes'
import cartes from './cartes'
import jeu from './jeu'
import aide from './aide'
import erreur from './erreur'

Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [
		accueil,
		authentification.inscription,
		authentification.connexion,
		authentification.motDePasseOublie,
		authentification.reinitialisationMotDePasse,
		authentification.validationCompte,
		compte,
		mondes.creation,
		mondes.rejoindre,
		mondes.liste,
		mondes.lobby,
		cartes.creation,
		cartes.editer,
		cartes.liste,
		jeu,
		aide,
		erreur.erreur404
	]
})