const changer = (idMonde, idEquipe) => {
	return document.axios.post('equipes/' + idMonde + '/' + idEquipe)
}

const modifier = (idMonde, idEquipe, donnees) => {
	return document.axios.put('equipes/' + idMonde + '/' + idEquipe, donnees)
}

const quitter = (idMonde) => {
	return document.axios.post('equipes/' + idMonde)
}

export default {
    changer,
    modifier,
    quitter
}