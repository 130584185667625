import Icone from '@/components/blocs/Icone/Icone.vue'

export default {
	components: {
		Icone
	},
	data() {
		return {
			afficherUnites: this.$vuetify.breakpoint.lgAndUp
		}
	},
	computed: {
		village() {
			return this.$store.state.village
		},
		ordres() {
			return this.$store.state.ordres
		},
		soutiens() {
			if (!this.ordres) return {}

			const soutiens = this.ordres.soutiens.externe
				.concat(this.ordres.soutiens.interne)
				.filter(soutien => soutien.villageArrivee.id == this.village.id)

			return soutiens.reduce((total, soutien) => {
				total.guerriers += soutien.guerriers
				total.epeistes += soutien.epeistes
				total.archers += soutien.archers
				total.cavaliers += soutien.cavaliers
				total.beliers += soutien.beliers
				total.catapultes += soutien.catapultes
				return total
			}, {
				guerriers: 0,
				epeistes: 0,
				archers: 0,
				cavaliers: 0,
				beliers: 0,
				catapultes: 0,
			})
		}
	}
}