import ConteneurBatiment from '@/components/blocs/ConteneurBatiment/ConteneurBatiment.vue'

export default {
    components: {
        ConteneurBatiment
    },
    data () {
        return {
            nomBatiment: this.$route.name.split('.')[1]
        }
    },
    computed: {
        village() {
            return this.$store.state.village
        },
        limiteActuelle() {
            return this.village[this.nomBatiment].getMontant()
        },
        prochaineLimite() {
            return this.village[this.nomBatiment].getProchainMontant()
        },
        ressource() {
            let ressource

            switch (this.nomBatiment) {
                case 'camp':
                    ressource = 'de bois'
                break;
                case 'carriere':
                    ressource = 'd\'argile'
                break;
                case 'mine':
                    ressource = 'de fer'
                break;
            }
            return ressource
        },
        description() {
            let debut

            switch (this.nomBatiment) {
                case 'camp':
                    debut = 'Le camp de bûcherons'
                break;
                case 'carriere':
                    debut = 'La carrière d\'argile'
                break;
                case 'mine':
                    debut = 'La mine de fer'
                break;
            }
            return debut + ' permet de récupérer une quantité ' + this.ressource + ' à chaque tour.'
        }
    }
}