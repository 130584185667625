import Icone from '@/components/blocs/Icone/Icone.vue'

export default {
	components: {
		Icone
	},
    model: {
        prop: 'ressourcesProp',
        event: 'change'
    },
    data () {
        return {
            bois: 0,
            argile: 0,
            fer: 0
        }
    },
    props: {
        ressourcesProp: Object
    },
    computed: {
        village () {
            return this.$store.state.village
        },
        marchandsRestants () {
            return this.village.marchands - this.bois - this.argile - this.fer
        },
        ressourcesVillages () {
            return {
                bois: Math.floor(this.village.bois / 1000),
                argile: Math.floor(this.village.argile / 1000),
                fer: Math.floor(this.village.fer / 1000)
            }
        },
        max () {
            return {
                bois: this.bois + this.marchandsRestants,
                argile: this.argile + this.marchandsRestants,
                fer: this.fer + this.marchandsRestants
            }
        },
        ressources () {
            return {
                bois: this.bois,
                argile: this.argile,
                fer: this.fer
            }
        }
    },
    watch: {
        ressources () {
            this.$emit('change', this.ressources)
        },
        ressourcesProp () {
            this.bois = this.ressourcesProp.bois || 0
            this.argile = this.ressourcesProp.argile || 0
            this.fer = this.ressourcesProp.fer || 0
        }
    }
}