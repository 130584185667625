import Avatar from '@/components/blocs/Avatar/Avatar.vue'
import Conversation from '@/classes/Conversation'

export default {
    components: {
        Avatar
    },
	data() {
		return {
            message: null,
            actif: false
        }
    },
    props: {
        conversation: Conversation
    },
    methods: {
        toucheEntree(e) {
            if (!e.shiftKey) {
                e.preventDefault()
                this.envoyerMessage()
            }
        },
        envoyerMessage() {
            if (this.message && this.message.trim().length) {
                const donnees = {
                    contenu: this.message
                }
                this.message = null
                this.$serveur.messages.envoyerMessage(this.conversation.id, donnees).then(reponse => {
                    this.conversation.idDernierMessageVu = reponse.data.id
                }, erreur => {
                    if (erreur.response.status == 400) {
                        this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
                    } else if (erreur.response.status == 422) {
                        this.$root.$children[0].ajouterNotification(erreur.response.data.erreurs.contenu, 'error')
                    }
                    this.message = donnees.contenu
                })
            }
        },
        scrollMessages() {
            this.$refs.messages.scrollTo(0, this.$refs.messages.scrollHeight)
        },
        marquerCommeVu() {
            if (this.conversation.dernierMessage) {
                this.$serveur.messages.marquerMessageCommeVu(this.conversation.dernierMessage.id).then(() => {
                    this.conversation.idDernierMessageVu = this.conversation.dernierMessage.id
                })
            }
        },
        onNouveauMessage() {
            this.scrollMessages()
            this.marquerCommeVu()
        }
    },
    watch: {
        conversation() {
            this.scrollMessages()
        }
    },
    mounted() {
        setTimeout(() => {
            this.scrollMessages()
            this.marquerCommeVu()
        }, 10)
        
        document.onNouveauMessage = this.onNouveauMessage
    },
    destroyed() {
        document.onNouveauMessage = null
    }
}