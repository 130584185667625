import { unites } from 'friendswars.shared'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Icone from '@/components/blocs/Icone/Icone.vue'
import LienVillage from '@/components/blocs/LienVillage/LienVillage.vue'

export default {
	components: {
		BlocAction,
		Icone,
		LienVillage
	},
	data () {
		return {
			unites,
			unitesSansEspion: Object.fromEntries(Object.entries(unites).filter(([nom]) => nom != 'espions')),
			soutienSelectionne: null,
			afficherDetailsSoutien: false,
			indexVillageCible: 0
		}
	},
	computed: {
		villagesDuJoueur () {
			return this.$store.state.villagesDuJoueur
		},
		village () {
			return this.$store.state.village
		},
		villageCible () {
			if (this.indexVillageCible) {
				return this.villagesDuJoueur[this.indexVillageCible - 1]
			}
		},
		ordres() {
			return this.$store.state.ordres
		},
		armeesExterne() {
			const attaques = this.ordres.attaques.externe
				.filter(a => !this.villageCible || a.villageDefenseur.id == this.villageCible.id)

			const soutiens = this.ordres.mouvementsArmees.externe
				.filter(a => !this.villageCible || a.villageArrivee.id == this.villageCible.id)

			attaques.sort(Array.triNombre(e => e.tourArrivee))
			soutiens.sort(Array.triNombre(e => e.tourArrivee))

			return {
				attaques,
				soutiens
			}
		},
		mouvementsInterne() {
			const attaquesAller = this.ordres.attaques.interne.aller.map(attaque => {
					attaque.aller = true
					attaque.tour = attaque.tourArrivee
					return attaque
				})
			const attaquesRetour = this.ordres.attaques.interne.retour.map(attaque => {
					attaque.aller = false
					attaque.tour = attaque.tourRetour
					return attaque
				})

			const mouvementsInterne = this.ordres.mouvementsArmees.interne.map(mouvement => {
					mouvement.aller = mouvement.type == 1
					mouvement.tour = mouvement.tourArrivee
					return mouvement
				})
				.filter(a => !this.villageCible || a.villageDepart.id == this.villageCible.id || a.villageArrivee.id == this.villageCible.id)
			
			const espionnages = this.ordres.espionnages.map(espionnage => {
					espionnage.aller = this.$store.state.monde.tour < espionnage.tourArrivee
					espionnage.tour = espionnage.aller ? espionnage.tourArrivee : espionnage.tourRetour
					return espionnage
				})
				.filter(a => !this.villageCible || a.villageAttaquant.id == this.villageCible.id)

			const attaques = attaquesAller.concat(attaquesRetour)
				.filter(a => !this.villageCible || a.villageAttaquant.id == this.villageCible.id)
			
			attaques.sort(Array.triNombre(e => e.tour))
			mouvementsInterne.sort(Array.triNombre(e => e.tour))
			espionnages.sort(Array.triNombre(e => e.tour))

			return {
				attaques,
				soutiens: mouvementsInterne,
				espionnages
			}
		}
	},
	methods: {
		renvoyerSoutien() {
			this.$serveur.ordres.renvoyerSoutien(this.soutienSelectionne.id).then((reponse) => {
				this.$store.commit('actualiserOrdres', reponse.data.ordres)
				this.afficherDetailsSoutien = false
			}, (erreur) => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		}
	},
	watch: {
		soutienSelectionne() {
			if (this.soutienSelectionne) {
				this.afficherDetailsSoutien = true
			}
		},
		afficherDetailsSoutien() {
			if (!this.afficherDetailsSoutien) {
				this.soutienSelectionne = null
			}
		}
	},
	beforeCreate() {
		if (this.$store.state.monde.utilisateur.arret) {
			this.$router.replace({
				name: 'jeu'
			})
		}
	}
}