const getListeOrdres = (idMonde) => {
	return document.axios.get('ordres/' + idMonde)
}

const attaquerVillage = (idVillageAttaquant, idVillageDefenseur, donnees) => {
	return document.axios.post('ordres/attaquer/' + idVillageAttaquant + '/' + idVillageDefenseur, donnees)
}

const soutenirVillage = (idVillageDepart, idVillageArrivee, donnees) => {
	return document.axios.post('ordres/soutenir/' + idVillageDepart + '/' + idVillageArrivee, donnees)
}

const espionnerVillage = (idVillageDepart, idVillageArrivee, donnees) => {
	return document.axios.post('ordres/espionner/' + idVillageDepart + '/' + idVillageArrivee, donnees)
}

const approvisionnerVillage = (idVillageDepart, idVillageArrivee, donnees) => {
	return document.axios.post('ordres/approvisionner/' + idVillageDepart + '/' + idVillageArrivee, donnees)
}

const renvoyerSoutien = (id) => {
	return document.axios.post('ordres/soutien/' + id + '/renvoyer')
}

const annulerAttaque = (idAttaque) => {
	return document.axios.delete('ordres/annuler/attaque/' + idAttaque)
}

const annulerSoutien = (idSoutien) => {
	return document.axios.delete('ordres/annuler/soutien/' + idSoutien)
}

const annulerEspionnage = (idEspionnage) => {
	return document.axios.delete('ordres/annuler/espionnage/' + idEspionnage)
}

const annulerEchange = (idEchange) => {
	return document.axios.delete('ordres/annuler/echange/' + idEchange)
}

const annulerApprovisionnement = (idMouvementRessources) => {
	return document.axios.delete('ordres/annuler/approvisionnement/' + idMouvementRessources)
}

export default {
	getListeOrdres,
	attaquerVillage,
	soutenirVillage,
	espionnerVillage,
	approvisionnerVillage,
	renvoyerSoutien,
	annulerAttaque,
	annulerSoutien,
	annulerEspionnage,
	annulerApprovisionnement,
	annulerEchange
}