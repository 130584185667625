import { unites } from "friendswars.shared"

export default class Lit {
	constructor(contenu) {
		this.quantite = contenu.quantite
		this.nomUnite = contenu.nomUnite
		this.tourDebut = contenu.tourDebut
		this.tourFin = contenu.tourFin
        this.unite = unites[this.nomUnite]
	}
}