import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Bouton from '@/components/blocs/Bouton/Bouton.vue'
import { abonnerNotifications } from '@/modules/notifications'

export default {
	components: {
		BlocAction,
		Bouton
	},
	data() {
		return {
			nom: null,
			motDePasse: null,
			montrerMotDePasse: false,
			erreurs: {}
		}
	},
	methods: {
		valider() {
			const donnees = {
				nom: this.nom,
				motDePasse: this.motDePasse,
			}
			this.$serveur.authentification.connexion(donnees).then((reponse) => {
				this.erreurs = {}

				// Stocke les données de l'utilisateur.
				reponse.data.utilisateur.dateCreation = this.$moment(reponse.data.utilisateur.dateCreation)
				this.$store.state.utilisateur = reponse.data.utilisateur
				this.$store.state.jeton = reponse.data.jeton
				localStorage.setItem('jeton', reponse.data.jeton)

				abonnerNotifications()
				
				this.$socket.emit('verification-socket', reponse.data.jetonVerificationSocket)

				// Redirige vers la liste des mondes.
				this.$router.push({
					name: 'mondes'
				})
			}, (erreur) => {
				if (erreur.response.status == 422) {
					this.erreurs = erreur.response.data.erreurs
				}
			})
		}
	}
}