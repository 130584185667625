import store from '@/store'

export default class MouvementRessources {
    constructor(contenu) {
        this.id = contenu.id
        this.tourDepart = contenu.tourDepart
        this.tourArrivee = contenu.tourArrivee
        this.tourRetour = contenu.tourRetour
        this.bois = contenu.bois
        this.argile = contenu.argile
        this.fer = contenu.fer
        this.marchands = contenu.marchands
        this.villageDepart = store.state.villages.find(v => v.id == contenu.idVillageDepart)
        this.villageArrivee = store.state.villages.find(v => v.id == contenu.idVillageArrivee)
    }
}