Number.prototype.formate = function(reduit = false) {
	if (this >= 1000000) {
		return (Math.floor(this / 10000) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + 'M'
	}
	if (this >= 10000 && reduit) {
		return (Math.floor(this / 10) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + 'K'
	}
	return Math.floor(this).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

Number.prototype.arrondi = function() {
	let arrondi = 1

	if (this > 100) {
		arrondi *= 10

		if (this > 10000) {
			arrondi *= 10
		}

		if (this > 100000) {
			arrondi *= 10
		}
	}

	return Math.ceil(this / arrondi) * arrondi
}

Number.aleatoire = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1)) + min
}

Number.calculerDistance = (ligne1, colonne1, ligne2, colonne2) => {
	const cote1 = ligne2 - ligne1
	const cote2 = (ligne2 % 2 == 0 ? colonne2 : colonne2 + 0.5) - colonne1

	return Math.sqrt(cote1 ** 2 + cote2 ** 2)
}

String.prototype.premiereLettreMajuscule = function() {
	return this.toUpperCase()[0] + this.substr(1)
}

String.prototype.commenceParVoyellle = function() {
	return ['a', 'e', 'i', 'o', 'u', 'y'].includes(this.toLowerCase()[0])
}

Array.triNombre = (fonction, decroissant = false) => {
	return (a, b) => {
		return decroissant ? fonction(b) - fonction(a) : fonction(a) - fonction(b)
	}
}

Array.triObjet = (fonction) => {
	return (a, b) => {
		if (fonction(a) < fonction(b)) {
			return -1
		} else if (fonction(a) > fonction(b)) {
			return 1
		} else {
			return 0
		}
	}
}

Array.prototype.remove = function(item) {
    const index = this.indexOf(item)
    if (index != -1) {
        this.removeAt(index)
        return true
    }
    return false
}

Array.prototype.removeAt = function(index) {
    if (index >= 0) {
        this.splice(index, 1)
        return true
    }
    return false
}

Array.prototype.groupBy = function(key) {
	return this.reduce(function(rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x)
		return rv
	}, {})
}