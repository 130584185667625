import moment from 'moment'
import store from '@/store'
import Equipe from './Equipe'
import Utilisateur from './Utilisateur'

export default class Monde {
	constructor(contenu) {
		this.id = contenu.id
		this.nom = contenu.nom
		this.code = contenu.code
		this.idCreateur = contenu.idCreateur
		this.equipes = (contenu.equipes || []).map(e => new Equipe(e))
		this.utilisateurs = (contenu.utilisateurs || []).map(u => {
			const utilisateur = new Utilisateur(u)
			utilisateur.equipe = this.equipes.find(e => e.id == u.idEquipe)
			utilisateur.equipe.utilisateurs.push(utilisateur)
			return utilisateur
		})
		this.nombreJoueurs = contenu.nombreJoueurs
		this.nombreLignes = contenu.nombreLignes
		this.nombreColonnes = contenu.nombreColonnes
		this.ligneTour = contenu.ligneTour
		this.colonneTour = contenu.colonneTour
		this.tour = contenu.tour
		this.tauxChange = contenu.tauxChange
		this.variationChange = contenu.variationChange
		this.dateCreation = moment(contenu.dateCreation)

		if (contenu.dateLancement) {
			this.dateLancement = moment(contenu.dateLancement)
		} else {
			this.dateLancement = null
		}

		if (contenu.dateDernierTour) {
			this.dateDernierTour = moment(contenu.dateDernierTour)
		} else {
			this.dateDernierTour = null
		}

		if (contenu.dateFin) {
			this.dateFin = moment(contenu.dateFin)
		} else {
			this.dateFin = null
		}
	}

	get utilisateur () {
		return this.utilisateurs.find(utilisateur => utilisateur.id == store.state.utilisateur.id)
	}
}