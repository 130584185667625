import VueApexCharts from 'vue-apexcharts'
import HautDePageJeu from '@/components/blocs/HautDePageJeu/HautDePageJeu.vue'
import MenuJeu from '@/components/blocs/MenuJeu/MenuJeu.vue'
import AfficheurUnites from '@/components/blocs/AfficheurUnites/AfficheurUnites.vue'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Carte from '@/components/blocs/Carte/Carte.vue'
import RapportAttaque from '@/classes/RapportAttaque'
import RapportEspionnage from '@/classes/RapportEspionnage'
import RapportMouvementArmee from '@/classes/RapportMouvementArmee'
import RapportSoutienAttaque from '@/classes/RapportSoutienAttaque'
import RapportInformation from '@/classes/RapportInformation'
import Village from '@/classes/Village'
import Evenement from '@/classes/Evenement'
import Conversation from '@/classes/Conversation'

export default {
	components: {
		apexchart: VueApexCharts,
		HautDePageJeu,
		MenuJeu,
		AfficheurUnites,
		BlocAction,
		Carte
	},
	data() {
		return {
			carte: null,
			deplacement: false,
			villagesCliquable: true,
			afficherAide: false
		}
	},
	computed: {
		zoom() {
			return this.$store.state.zoom
		},
		village() {
			return this.$store.state.village
		},
		villagesDuJoueur() {
			return this.$store.state.villagesDuJoueur
		},
		villages() {
			return this.$store.state.villages
		},
		villageCible() {
			if (this.$route.name.startsWith('jeu.carte.village')) {
				return this.$store.state.villages.find(v => v.ligne == this.$route.params.ligne && v.colonne == this.$route.params.colonne)
			}

			return null
		},
		tourCiblee() {
			return this.$route.name == 'jeu.carte.tour'
		},
		afficherInterface() {
			return this.$route.name != 'jeu' && this.$store.state.afficherInterface
		}
	},
	methods: {
		debutDeplacement() {
			this.$timerDeplacement = setTimeout(() => {
				this.deplacement = true
			}, 100);
		},
		finDeplacement() {
			this.deplacement = false
			if (this.$timerDeplacement) {
				clearTimeout(this.$timerDeplacement)
			}
			this.$timerDeplacement = null
		},
		type(village) {
			if (village.idUtilisateur) {
				return 'joueur'
			}

			return 'barbare'
		},
		chargerCarte() {
			this.$serveur.cartes.getCarteDuMonde(this.$store.state.monde.id).then((reponse) => {
				this.carte = reponse.data.carte
			})
		},
		chargerVillages() {
			this.$serveur.villages.getVillagesDuMonde(this.$store.state.monde.id).then((reponse) => {
				this.$store.state.villages = reponse.data.villages.map(contenu => new Village(contenu))
				const villagesDuJoueur = this.$store.state.villages.filter(village => village.idUtilisateur == this.$store.state.utilisateur.id)
				villagesDuJoueur.sort(Array.triObjet(v => v.nom))
				this.$store.state.villagesDuJoueur = villagesDuJoueur
				
				if (this.$store.state.villagesDuJoueur.length && !this.$store.state.monde.dateFin) {
					this.$store.state.village = this.$store.state.villagesDuJoueur[0]
				}

				this.$store.state.tourTermine = this.$store.state.monde.utilisateur.tourTermine

				this.chargerRapports()
				this.chargerEvenements()
				this.chargerOrdres()
				this.chargerMessages()
				this.chargerCarte()
			})
		},
		chargerRapports() {
			this.$serveur.rapports.getListeRapports(this.$store.state.monde.id).then((reponse) => {
				const rapports = reponse.data.rapports.attaques.map(contenu => new RapportAttaque(contenu))
					.concat(reponse.data.rapports.espionnages.map(contenu => new RapportEspionnage(contenu)))
					.concat(reponse.data.rapports.mouvementsArmees.map(contenu => new RapportMouvementArmee(contenu)))
					.concat(reponse.data.rapports.soutiensAttaques.map(contenu => new RapportSoutienAttaque(contenu)))
					.concat(reponse.data.rapports.informations.map(contenu => new RapportInformation(contenu)))

				rapports.sort(Array.triNombre(r => r.tour, true))

				this.$store.state.rapports = rapports
			}, (erreur) => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		chargerEvenements() {
			this.$serveur.evenements.getListeEvenements(this.$store.state.monde.id).then((reponse) => {
				this.$store.state.evenements = reponse.data.evenements.map(contenu => new Evenement(contenu))
			}, (erreur) => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		chargerOrdres() {
			this.$serveur.ordres.getListeOrdres(this.$store.state.monde.id).then((reponse) => {
				this.$store.commit('actualiserOrdres', reponse.data.ordres)
			}, (erreur) => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		chargerMessages() {
			this.$serveur.messages.getListeMessages(this.$store.state.monde.id).then((reponse) => {
				this.$store.state.conversations = reponse.data.conversations.map(contenu => new Conversation(contenu))
			}, (erreur) => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		ciblerVillage(village) {
			if (this.villagesCliquable) {
				this.$store.state.afficherInterface = true
				if (village) {
					if (this.villageCible && this.villageCible.position == village.position && this.villageCible.idUtilisateur == this.$store.state.utilisateur.id) {
						this.$store.state.village = this.villageCible
						this.$router.push({
							name: 'jeu'
						})
					} else if (this.$route.fullPath != '/jeu/' + village.ligne + '/' + village.colonne + '/') {
						const route = {
							name: 'jeu.carte.village',
							params: {
								ligne: village.ligne,
								colonne: village.colonne
							}
						}
						if (this.$route.name == 'jeu.carte.village') {
							this.$router.replace(route)
						} else {
							this.$router.push(route)
						}
					}
				} else {
					if (this.$route.name != 'jeu') {
						this.$router.push({
							name: 'jeu'
						})
					}
				}
			}
		},
		villageSuivant() {
			if (this.villagesDuJoueur.length > 1) {
				const index = this.villagesDuJoueur.indexOf(this.village)
				const nouvelIndex = this.villagesDuJoueur.length > index + 1 ? index + 1 : 0
				this.$store.state.village = this.villagesDuJoueur[nouvelIndex]
				
				if (this.$route.name != 'jeu' && this.$route.name != 'jeu.village') {
					this.$router.push({
						name: 'jeu'
					})
				}
			}
		},
		villagePrecedent() {
			if (this.villagesDuJoueur.length > 1) {
				const index = this.villagesDuJoueur.indexOf(this.village)
				const nouvelIndex = index >= 1 ? index - 1 : this.villagesDuJoueur.length - 1
				this.$store.state.village = this.villagesDuJoueur[nouvelIndex]
				
				if (this.$route.name != 'jeu' && this.$route.name != 'jeu.village') {
					this.$router.push({
						name: 'jeu'
					})
				}
			}
		},
		afficherPage(nom) {
			if (this.$route.name != nom) {
				this.$router.push({
					name: nom
				})
			}
		},
		centrerCarte() {
			this.$refs.carte.centrerCarte()
		},
		onNouvellesCoordonnees(coordonnees) {
			this.carte.coordonnees.forEach(coordonnee => {
				const coordonneeModifiee = coordonnees.find(c => c.id == coordonnee.id)
				if (coordonneeModifiee) {
					coordonnee.type = coordonneeModifiee.type
				}
			})
		}
	},
	watch: {
		$route() {
			this.$store.state.afficherInterface = true
			setTimeout(() => {
				this.$refs.carte.redimensionnement()
			}, 10)
			if (this.$refs.interfaceConteneur) {
				this.$refs.interfaceConteneur.scrollTo(0, 0)
			}
			if (this.$route.name.startsWith('jeu.carte.')) {
				this.centrerCarte()
			}
		},
		deplacement() {
			if (this.$deplacementTimer) {
				clearTimeout(this.$deplacementTimer)
				this.$deplacementTimer = null
			}

			if (this.deplacement) {
				this.villagesCliquable = false
			} else {
				this.$deplacementTimer = setTimeout(() => {
					this.villagesCliquable = true
				}, 10)
			}
		}
	},
	mounted() {
		if (this.$vuetify.breakpoint.smAndDown) {
			this.$store.state.zoom = 1
		} else if (this.$vuetify.breakpoint.lgAndDown) {
			this.$store.state.zoom = 2
		} else {
			this.$store.state.zoom = 3
		}
		
		this.$store.state.village = null

		if (this.$store.state.monde) {
			// Rejoins le salon du monde pour recevoir les évènements.
			this.$socket.emit('salon:rejoindre', 'monde-' + this.$store.state.monde.id)

			this.chargerVillages()
		}

        document.onNouvellesCoordonnees = this.onNouvellesCoordonnees

		this.$mousetrap.bind('?', () => this.afficherAide = true)

		this.$mousetrap.bind('tab', () => {
			this.villageSuivant()
			return false
		})
		this.$mousetrap.bind('shift+tab', () => {
			this.villagePrecedent()
			return false
		})

		this.$mousetrap.bind('-', () => {
			if (this.$store.state.zoom > 1) {
				this.$store.state.zoom--
			}
		})

		this.$mousetrap.bind('+', () => {
			if (this.$store.state.zoom < 4) {
				this.$store.state.zoom++
			}
		})

		this.$mousetrap.bind('enter', () => this.centrerCarte())

		this.$mousetrap.bind('shift+enter', () => {
			if (this.village.actionsRestantes) {
				this.$refs.menu.engagerTravailleurs()
			}
		})

		this.$mousetrap.bind('ctrl+enter', () => {
			if (this.$store.state.tourTermine) {
				if (this.$store.state.joueursEnAttente.length) {
					this.$refs.menu.reprendreTour()
				}
			} else {
				const nombreActions = this.villagesDuJoueur.reduce((a, b) => a + b.actionsRestantes, 0)
				if (!nombreActions) {
					this.$refs.menu.terminerTour()
				} else {
					this.$refs.menu.villageSuivant()
				}
			}
		})

		this.$mousetrap.bind('v', () => this.afficherPage('jeu.village'))
		this.$mousetrap.bind('e', () => this.afficherPage('jeu.entrepot'))
		this.$mousetrap.bind('f', () => this.afficherPage('jeu.ferme'))
		this.$mousetrap.bind('c', () => this.afficherPage('jeu.caserne'))
		this.$mousetrap.bind('m', () => this.afficherPage('jeu.marche'))
		this.$mousetrap.bind('t', () => this.afficherPage('jeu.taverne'))
		this.$mousetrap.bind('h', () => this.afficherPage('jeu.hopital'))
	},
	beforeCreate() {
		if (!this.$store.state.monde) {
			this.$router.replace({
				name: 'mondes'
			})
		} else if (!this.$store.state.monde.dateLancement) {
			this.$router.replace({
				name: 'lobby'
			})
		}
	},
	destroyed() {
        document.onNouvellesCoordonnees = null

		this.$mousetrap.unbind('?')
		this.$mousetrap.unbind('tab')
		this.$mousetrap.unbind('shift+tab')
		this.$mousetrap.unbind('-')
		this.$mousetrap.unbind('+')
		this.$mousetrap.unbind('enter')
		this.$mousetrap.unbind('shift+enter')
		this.$mousetrap.unbind('ctrl+enter')
		this.$mousetrap.unbind('v')
		this.$mousetrap.unbind('e')
		this.$mousetrap.unbind('f')
		this.$mousetrap.unbind('c')
		this.$mousetrap.unbind('t')
		this.$mousetrap.unbind('m')
		this.$mousetrap.unbind('a')
		this.$mousetrap.unbind('h')

		window.removeEventListener('resize', this.centrerCarte)
		
		// Quitte le salon du monde.
		if (this.$store.state.monde) {
			this.$socket.emit('salon:quitter', 'monde-' + this.$store.state.monde.id)
		}
	}
}