import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Carte from '@/components/blocs/Carte/Carte.vue'
import tuiles from '@/modules/tuiles'

export default {
	components: {
		BlocAction,
        Carte
	},
	data() {
        return {
            nombreLignes: 13,
            nombreColonnes: 12,
            nombreJoueurs: 2,
            pret: false,
            carte: null,
            tuileSelectionnee: null,
            zoom: 1,
            tuiles,
            nom: null,
            chargementSauvegarde: false
        }
    },
    methods: {
        changeTuileSelectionnee (tuile) {
            if (this.tuileSelectionnee) {
                tuile.type = this.tuileSelectionnee
                this.$refs.carte.$forceUpdate()
            }
        },
        sauvegarder () {
            this.chargementSauvegarde = true
            const coordonnees = this.$refs.carte.getCoordonneesMappees()

            if (this.carte) {
                const donnees = {
                    nom: this.nom,
                    coordonnees
                }

                this.$serveur.cartes.modifier(this.carte.id, donnees)
                    .catch(erreur => {
                        if (erreur.response.status == 400) {
                            this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
                        }
                    })
                    .finally(() => this.chargementSauvegarde = false)
            } else {
                const donnees = {
                    nom: this.nom,
                    nombreLignes: this.nombreLignes,
                    nombreColonnes: this.nombreColonnes,
                    nombreJoueurs: this.nombreJoueurs,
                    coordonnees
                }

                this.$serveur.cartes.creer(donnees)
                    .then(reponse => {
                        this.$store.state.carte = reponse.data.carte
                        this.carte = reponse.data.carte
                        this.$router.replace('/editer-carte')
                    })
                    .catch(erreur => {
                        if (erreur.response.status == 400) {
                            this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
                        } else if (erreur.response.status == 422) {
                            this.$root.$children[0].ajouterNotification(erreur.response.data.erreurs.nom || 'La carte est invalide', 'error')
                        }
                    })
                    .finally(() => this.chargementSauvegarde = false)
            }
        }
    },
    watch: {
        nombreJoueurs() {
            this.nombreLignes = 11 + (this.nombreJoueurs)
            this.nombreColonnes = 10 + (this.nombreJoueurs)
        }
    },
    mounted () {
		if (this.$vuetify.breakpoint.smAndDown) {
			this.zoom = 1
		} else if (this.$vuetify.breakpoint.lgAndDown) {
			this.zoom = 2
		} else {
            this.zoom = 3
        }
        
        this.carte = this.$store.state.carte

        if (this.$route.name == 'editer.carte') {
            if (this.$store.state.carte) {
                this.carte = this.$store.state.carte
                this.nom = this.carte.nom
                this.nombreLignes = this.carte.nombreLignes
                this.nombreColonnes = this.carte.nombreColonnes
                this.nombreJoueurs = this.carte.nombreJoueurs
                this.pret = true
            } else {
                this.$router.replace('/cartes')
            }
        }
    }
}