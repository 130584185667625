export default {
    divers: {
        vide: 'Vide',
        ocean: 'Océan',
        terre: 'Terre'
    },
    prairie: {
        broussaille: 'Broussailles',
        plaine: 'Plaine',
        arbuste: 'Arbustes',
        ferme1: 'Ferme',
        ferme2: 'Ferme',
        ferme3: 'Ferme',
        ferme4: 'Ferme',
        coline: 'Coline',
    },
    'Forêt et Jungle': {
        foret: 'Forêt',
        jungle: 'Jungle',
        tourbiere: 'Tourbière',
        marais2: 'Marais',
        marais1: 'Marais'
    },
    montagne: {
        montagne: 'Montagne',
        grotte1: 'Grotte',
        grotte2: 'Grotte'
    },
    'Biome désertique': {
        desert1: 'Désert',
        ruinesDesert: 'Ruines',
        pyramide: 'Pyramide',
        oasis1: 'Oasis',
        oasis2: 'Oasis',
        oasis3: 'Oasis',
        desert3: 'Désert',
        desert2: 'Désert',
        montagneDesert: 'Montagne',
        colineDesert: 'Colines'
    },
    'Biome enneigé': {
        neige: 'Neige',
        montagneEnneigee: 'Montagne',
        foretEnneigee: 'Forêt enneigée',
        lacEnneige: 'Lac'
    },
    'Biome volcanique': {
        foretBrulee: 'Forêt brulée',
        cendre1: 'Cendre',
        cendre2: 'Cendre',
        volcan: 'Volcan'
    },
    village: {
        chateauTerre: 'Joueur',
        villageTerre: 'Barbare'
    }
}