import Icone from '@/components/blocs/Icone/Icone.vue'

export default {
	components: {
		Icone
	},
    model: {
        prop: 'unitesProp',
        event: 'change'
    },
    data () {
        return {
            guerriers: 0,
            epeistes: 0,
            archers: 0,
            cavaliers: 0,
            beliers: 0,
            catapultes: 0,
            espions: 0
        }
    },
    props: {
        unitesProp: Object,
        espionnage: Boolean
    },
    computed: {
        village () {
            return this.$store.state.village
        },
        unites () {
            if (this.espionnage) {
                return {
                    espions: this.espions
                }
            }
            return {
                guerriers: this.guerriers,
                epeistes: this.epeistes,
                archers: this.archers,
                cavaliers: this.cavaliers,
                beliers: this.beliers,
                catapultes: this.catapultes,
            }
        }
    },
    watch: {
        unites () {
            this.$emit('change', this.unites)
        },
        unitesProp () {
            this.guerriers = this.unitesProp.guerriers || 0
            this.epeistes = this.unitesProp.epeistes || 0
            this.archers = this.unitesProp.archers || 0
            this.cavaliers = this.unitesProp.cavaliers || 0
            this.beliers = this.unitesProp.beliers || 0
            this.catapultes = this.unitesProp.catapultes || 0
            this.espions = this.unitesProp.espions || 0
        }
    }
}