import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Avatar from '@/components/blocs/Avatar/Avatar.vue'

export default {
    components: {
        BlocAction,
        Avatar
    },
    data() {
        return {
            utilisateurs: []
        }
    },
    methods: {
        creerConversation() {
            const utilisateursMonde = this.$store.state.monde.utilisateurs.filter(utilisateur => utilisateur.id != this.$store.state.utilisateur.id)
            const utilisateursSelectionnes = this.utilisateurs.map(index => utilisateursMonde[index].id)
            utilisateursSelectionnes.push(this.$store.state.utilisateur.id)
            utilisateursSelectionnes.sort()
            
            const conversation = this.$store.state.conversations.find(conversation => {
                const utilisateurs = conversation.utilisateurs.map(utilisateur => utilisateur.id)
                utilisateurs.sort()
                return JSON.stringify(utilisateurs) == JSON.stringify(utilisateursSelectionnes)
            })

            if (conversation) {
                this.$emit('existant', conversation)
            } else {
                this.$serveur.messages.creerConversation(this.$store.state.monde.id, {
                    utilisateurs: utilisateursSelectionnes
                }).then(() => {}, erreur => {
                    if (erreur.response.status == 400) {
                        this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
                    }
                })
            }
        }
    }
}