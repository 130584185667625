import { unites, soldats } from 'friendswars.shared'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Icone from '@/components/blocs/Icone/Icone.vue'
import LienVillage from '@/components/blocs/LienVillage/LienVillage.vue'

export default {
	components: {
		BlocAction,
		Icone,
		LienVillage
	},
	data() {
		const unitesClone = Object.assign({}, unites)
		delete unitesClone.espions
		return {
			unites: unitesClone,
			soldats
		}
	},
	computed: {
		chance() {
			return this.rapport.utilisateurAttaquant.id != this.$store.state.utilisateur.id ? this.rapport.chance : -this.rapport.chance
		},
		rapport() {
			return this.$store.state.rapportSelectionne
		},
		afficherInformationDefenseur() {
			if (this.rapport) {
				return this.rapport.utilisateurAttaquant.id != this.$store.state.utilisateur.id || this.rapport.pourcentagePerteAttaquant != 100
			}
		},
		pourcentageAttaquant() {
			if (this.rapport) {
				const pourcentageAttaquant = this.rapport.pourcentagePerteAttaquant < 100
					? 100 - this.rapport.pourcentagePerteAttaquant
					: this.rapport.pourcentagePerteDefenseur
				return Math.round(pourcentageAttaquant * 100) / 100
			}
		},
		pourcentageDefenseur() {
			if (this.rapport) {
				const pourcentageAttaquant = this.rapport.pourcentagePerteAttaquant < 100
					? 100 - this.rapport.pourcentagePerteAttaquant
					: this.rapport.pourcentagePerteDefenseur
				return Math.round((100 - pourcentageAttaquant) * 100) / 100
			}
		},
		ressourcesPillees() {
			if (this.rapport) {
				return this.rapport.bois + this.rapport.argile + this.rapport.fer
			}
		},
		ressourcesPillables() {
			if (this.rapport) {
				return (this.rapport.guerriersAttaquant - this.rapport.guerriersPerdusAttaquant) * unites.guerriers.transport
					+ (this.rapport.epeistesAttaquant - this.rapport.epeistesPerdusAttaquant) * unites.epeistes.transport
					+ (this.rapport.archersAttaquant - this.rapport.archersPerdusAttaquant) * unites.archers.transport
					+ (this.rapport.cavaliersAttaquant - this.rapport.cavaliersPerdusAttaquant) * unites.cavaliers.transport
					+ (this.rapport.beliersAttaquant - this.rapport.beliersPerdusAttaquant) * unites.beliers.transport
					+ (this.rapport.catapultesAttaquant - this.rapport.catapultesPerdusAttaquant) * unites.catapultes.transport
			}
		}
	}
}