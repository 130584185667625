import { unites } from 'friendswars.shared'
import store from '@/store'
import Chateau from './Chateau'
import Entrepot from './Entrepot'
import Ferme from './Ferme'
import Camp from './Camp'
import Carriere from './Carriere'
import Mine from './Mine'
import Caserne from './Caserne'
import Muraille from './Muraille'
import Marche from './Marche'
import Taverne from './Taverne'
import Hopital from './Hopital'
import Construction from './Construction'
import Recrutement from './Recrutement'
import Achat from './Achat'
import Lit from './Lit'

export default class Village {
	constructor(contenu, loyautes = null, lits = null) {
		this.id = contenu.id
		this.type = contenu.type
		this.nom = contenu.nom
		this.ligne = contenu.ligne
		this.colonne = contenu.colonne
		this.position = '(' + (this.ligne < 10 ? '0' : '') + this.ligne + ' | ' + (this.colonne < 10 ? '0' : '') + this.colonne + ')'
		this.idUtilisateur = contenu.idUtilisateur
		this.utilisateur = store.state.monde.utilisateurs.find(u => u.id == this.idUtilisateur)
		this.couleur = this.utilisateur ? this.utilisateur.couleur : 'grey'
		if (loyautes || contenu.loyautes) {
			this.loyautes = loyautes || contenu.loyautes.map(loyaute => {
				return {
					valeur: loyaute.valeur,
					utilisateur: store.state.monde.utilisateurs.find(u => u.id == loyaute.idUtilisateur)
				}
			})
			this.loyautes.sort(Array.triNombre(l => l.valeur))
		}
		this.points = contenu.points
		this.achats = []
		this.actionsRestantes = contenu.actionsRestantes
		this.espionsRestants = contenu.espionsRestants

		if (contenu.constructions) {
			this.constructions = contenu.constructions.map(construction => new Construction(construction))
		} else {
			this.constructions = []
		}
		
		if (contenu.recrutements) {
			this.recrutements = contenu.recrutements.map(recrutement => new Recrutement(recrutement))
		} else {
			this.recrutements = []
		}
		
		if (contenu.achats) {
			this.achats = contenu.achats.map(achat => new Achat(achat))
		} else {
			this.achats = []
		}
		
		if (lits || contenu.lits) {
			this.lits = lits || contenu.lits.map(lit => new Lit(lit))
		} else {
			this.lits = []
		}

		this.actions = this.constructions.concat(this.recrutements, this.achats)

		// Ressources.
		this.bois = contenu.bois
		this.argile = contenu.argile
		this.fer = contenu.fer
		this.provisions = contenu.provisions

		// Batiments.
		this.chateau = new Chateau(contenu.chateau)
		this.entrepot = new Entrepot(contenu.entrepot)
		this.ferme = new Ferme(contenu.ferme)
		this.camp = new Camp(contenu.camp)
		this.carriere = new Carriere(contenu.carriere)
		this.mine = new Mine(contenu.mine)
		this.caserne = new Caserne(contenu.caserne)
		this.muraille = new Muraille(contenu.muraille)
		this.marche = new Marche(contenu.marche)
		this.taverne = new Taverne(contenu.taverne)
		this.hopital = new Hopital(contenu.hopital)

		this.batiments = {
			chateau: this.chateau,
			entrepot: this.entrepot,
			ferme: this.ferme,
			camp: this.camp,
			carriere: this.carriere,
			mine: this.mine,
			caserne: this.caserne,
			muraille: this.muraille,
			taverne: this.taverne,
			marche: this.marche,
			hopital: this.hopital
		}

		// Travailleurs.
		this.travailleurs = contenu.travailleurs

		// Marchands.
		this.marchands = contenu.marchands

		// Lits.
		this.litsRestants = contenu.litsRestants

		// Unités.
		this.epeistes = contenu.epeistes
		this.archers = contenu.archers
		this.guerriers = contenu.guerriers
		this.cavaliers = contenu.cavaliers
		this.beliers = contenu.beliers
		this.catapultes = contenu.catapultes
		this.espions = contenu.espions

		this.unites = {
			epeistes: this.epeistes,
			archers: this.archers,
			guerriers: this.guerriers,
			cavaliers: this.cavaliers,
			beliers: this.beliers,
			catapultes: this.catapultes
		}
	}

	getTotalVillageois() {
		let villageois = 0
		Object.values(this.batiments).forEach((batiment) => {
			villageois += batiment.getVillageois()
		})

		villageois += this.getVillageoisUnitesCaserne()
		villageois += this.espions * unites.espions.villageois

		return villageois
	}

	getPoints() {
		let points = 0
		Object.values(this.batiments).forEach((batiment) => {
			points += batiment.getPoints()
		})
		return points
	}

	getVillageoisUnitesCaserne() {
		let villageois = 0
		Object.entries(this.unites).forEach(([nom, unite]) => {
			villageois += unite * unites[nom].villageois
		})
		return villageois
	}

	getNombreActionsFaites() {
		return this.actions.length + this.travailleurs
	}

	getNombreTotalActions() {
		return this.chateau.niveau
	}
}