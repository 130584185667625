import { unites } from 'friendswars.shared'
import HautDePageInterface from '@/components/blocs/HautDePageInterface/HautDePageInterface.vue'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Icone from '@/components/blocs/Icone/Icone.vue'
import Chateau from '@/classes/Chateau'
import Entrepot from '@/classes/Entrepot'
import Ferme from '@/classes/Ferme'
import Camp from '@/classes/Camp'
import Carriere from '@/classes/Carriere'
import Mine from '@/classes/Mine'
import Caserne from '@/classes/Caserne'
import Muraille from '@/classes/Muraille'
import Marche from '@/classes/Marche'
import Taverne from '@/classes/Taverne'
import Hopital from '@/classes/Hopital'

export default {
	components: {
        HautDePageInterface,
		BlocAction,
		Icone
	},
	data() {
		return {
			batiments: {
				chateau: new Chateau(0),
				entrepot: new Entrepot(0),
				ferme: new Ferme(0),
				camp: new Camp(0),
				carriere: new Carriere(0),
				mine: new Mine(0),
				caserne: new Caserne(0),
				muraille: new Muraille(0),
				marche: new Marche(0),
				taverne: new Taverne(0),
				hopital: new Hopital(0)
			}
		}
	},
	props: {
		nomBatiment: String
	},
	computed: {
		batiment() {
			if (this.nomBatiment) {
				return this.batiments[this.nomBatiment]
			}
		},
		informationsBatiment() {
			switch (this.nomBatiment) {
				case 'chateau':
					return {
						travailleurs: 'Travailleurs',
						batiments: 'Bâtiments débloqués'
					}

				case 'caserne':
					return {
						quantite: 'Quantité<br>maximale',
						unites: 'Unités débloquées'
					}

				case 'muraille':
					return {
						bonus: 'Bonus de protection'
					}

				case 'entrepot':
					return {
						limite: 'Limite'
					}

				case 'ferme':
					return {
						limite: 'Limite'
					}

				case 'camp':
				case 'carriere':
				case 'mine':
					return {
						production: 'Production'
					}

				case 'marche':
					return {
						quantite: 'Marchands'
					}

				case 'taverne':
					return {
						quantite: 'Espions'
					}

				case 'hopital':
					return {
						quantite: 'Lits'
					}
			}
		},
		niveaux() {
			if (this.batiment) {
				let niveaux = []
				for (let niveau = 1; niveau <= this.batiment.niveauMaximum; niveau++) {
					this.batiment.niveau = niveau
					const informationsNiveau = {
						niveau: niveau,
						prixEnBois: this.batiment.getPrixEnBois().formate(),
						prixEnArgile: this.batiment.getPrixEnArgile().formate(),
						prixEnFer: this.batiment.getPrixEnFer().formate(),
						totalVillageois: this.batiment.getVillageois().formate(),
						totalPoints: this.batiment.getPoints().formate(),
						defense: this.batiment.getDefense().formate()
					}

					if (niveau == 1 && ['chateau', 'entrepot', 'ferme'].includes(this.nomBatiment)) {
						informationsNiveau.prixEnBois = '–'
						informationsNiveau.prixEnArgile = '–'
						informationsNiveau.prixEnFer = '–'
					}

					switch (this.nomBatiment) {
						case 'chateau':
							const batimentsDebloques = Object.values(this.batiments).filter(b => b.niveauChateauRequis == niveau)
							informationsNiveau.travailleurs = niveau
							informationsNiveau.batiments = batimentsDebloques.length ? batimentsDebloques.map(b => b.nom).join(' & ') : '—'
							break

						case 'caserne':
							const unitesDebloques = Object.values(unites).filter(u => u.niveauCaserneRequis == niveau)
							informationsNiveau.quantite = this.batiment.getQuantiteRecrutable()
							informationsNiveau.unites = unitesDebloques.length ? unitesDebloques.map(u => u.nom + 's').join(' & ') : '—'
							break

						case 'entrepot':
							informationsNiveau.limite = this.batiment.getLimite().formate()
							break

						case 'ferme':
							informationsNiveau.limite = this.batiment.getLimite().formate()
							break

						case 'camp':
						case 'carriere':
						case 'mine':
							informationsNiveau.production = this.batiment.getMontant().formate()
							break

						case 'muraille':
							informationsNiveau.bonus = (niveau * 5) + '%'
							break

						case 'marche':
							informationsNiveau.quantite = this.batiment.getMarchands().formate()
							break

						case 'taverne':
							informationsNiveau.quantite = niveau * 2
							break

						case 'hopital':
							informationsNiveau.quantite = this.batiment.getQuantiteSoignable().formate()
							break
					}

					niveaux.push(informationsNiveau)
				}

				return niveaux
			}
		}
	}
}