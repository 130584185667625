const ameliorerBatiment = (idVillage, nomBatiment) => {
	return document.axios.post('actions/' + idVillage + '/batiment/' + nomBatiment + '/ameliorer')
}

const recruterUnites = (idVillage, nomUnite, donnees) => {
	return document.axios.post('actions/' + idVillage + '/batiment/caserne/recruter/' + nomUnite, donnees)
}

const recruterEspion = (idVillage) => {
	return document.axios.post('actions/' + idVillage + '/batiment/taverne/recruter/espion')
}

const echangerRessources = (idVillage, donnees) => {
	return document.axios.post('actions/' + idVillage + '/batiment/marche/echanger', donnees)
}

const engagerTravailleurs = (idVillage) => {
	return document.axios.post('actions/' + idVillage + '/engager-travailleurs')
}

const annulerConstruction = (idConstruction) => {
	return document.axios.delete('actions/annuler/construction/' + idConstruction)
}

const annulerRecrutement = (idRecrutement) => {
	return document.axios.delete('actions/annuler/recrutement/' + idRecrutement)
}

const annulerAchat = (idAchat) => {
	return document.axios.delete('actions/annuler/achat/' + idAchat)
}

const annulerTravailleurs = (idVillage) => {
	return document.axios.delete('actions/annuler/travailleurs/' + idVillage)
}

export default {
	ameliorerBatiment,
	recruterUnites,
	recruterEspion,
	echangerRessources,
	engagerTravailleurs,
	annulerConstruction,
	annulerRecrutement,
	annulerAchat,
	annulerTravailleurs
}
