import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Bouton from '@/components/blocs/Bouton/Bouton.vue'

export default {
	components: {
		BlocAction,
		Bouton
	},
	beforeCreate() {
		// Si l'utilisateur est connecté, redirige vers la liste des mondes.
		if (this.$store.state.utilisateur) {
			this.$router.replace({
				name: 'mondes'
			})
		}
	}
}