const creation = (donnees) => {
	return document.axios.post('mondes/creation', donnees)
}

const rejoindre = (donnees) => {
	return document.axios.post('mondes/rejoindre', donnees)
}

const demarrer = (idMonde) => {
	return document.axios.post('mondes/demarrer/' + idMonde)
}

const supprimer = (idMonde) => {
	return document.axios.delete('mondes/' + idMonde)
}

const abandonner = (idMonde) => {
	return document.axios.post('mondes/abandonner/' + idMonde)
}

const changerCouleur = (idMonde, donnees) => {
	return document.axios.put('mondes/couleur/' + idMonde, donnees)
}

const getListeUtilisateur = () => {
	return document.axios.get('mondes')
}

const getListeUtilisateurEnAttente = (idMonde) => {
	return document.axios.get('mondes/' + idMonde + '/attente')
}

export default {
	creation,
	rejoindre,
	demarrer,
	supprimer,
	abandonner,
	changerCouleur,
	getListeUtilisateur,
	getListeUtilisateurEnAttente
}