import { unites } from 'friendswars.shared'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Bouton from '@/components/blocs/Bouton/Bouton.vue'
import BarProgression from '@/components/blocs/BarProgression/BarProgression.vue'
import AfficheurPrix from '@/components/blocs/AfficheurPrix/AfficheurPrix.vue'
import ConteneurBatiment from '@/components/blocs/ConteneurBatiment/ConteneurBatiment.vue'

export default {
	components: {
		BlocAction,
		Bouton,
		BarProgression,
		AfficheurPrix,
		ConteneurBatiment
	},
	data() {
		return {
			unites: {
				guerriers: unites.guerriers,
				epeistes: unites.epeistes,
				archers: unites.archers,
				cavaliers: unites.cavaliers,
				beliers: unites.beliers,
				catapultes: unites.catapultes,
			},
			chargement: false,
			popup: false,
			uniteSelectionne: null,
			nomUniteSelectionne: null,
			quantite: 0,
			quantiteMax: 0,
			quantiteRecrutable: 0
		}
	},
	computed: {
		village() {
			return this.$store.state.village
		}
	},
	methods: {
		selectionnerUnite(unite, nomUnite) {
			this.uniteSelectionne = unite
			this.nomUniteSelectionne = nomUnite
			this.quantite = 0
			this.popup = true
			this.quantiteMax = Math.floor(this.village.caserne.getQuantiteRecrutable() / unite.villageois)

			const quantitesMax = [
				Math.floor(this.$store.state.village.provisions / unites[nomUnite].villageois),
				Math.floor(this.$store.state.village.bois / unites[nomUnite].prixEnBois),
				Math.floor(this.$store.state.village.argile / unites[nomUnite].prixEnArgile),
				Math.floor(this.$store.state.village.fer / unites[nomUnite].prixEnFer)
			]

			quantitesMax.sort((a, b) => a - b)
			this.quantiteRecrutable = quantitesMax[0]

			if (this.quantiteRecrutable < this.quantiteMax) {
				this.quantite = quantitesMax[0]
			} else {
				this.quantite = this.quantiteMax
			}
		},
		recruter() {
			this.chargement = true
			const idVillage = this.$store.state.village.id
			const donnees = {
				quantite: this.quantite
			}
			this.$serveur.actions.recruterUnites(idVillage, this.nomUniteSelectionne, donnees).then((reponse) => {
				this.$store.commit('actualiserVillage', reponse.data.village)
				this.popup = false
			}, (erreur) => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			}).finally(() => this.chargement = false)
		}
	}
}