import PageJeu from '../components/pages/jeu/PageJeu/PageJeu.vue'
import PageCarteVillage from '../components/pages/jeu/PageCarteVillage/PageCarteVillage.vue'
import PageActionsVillage from '../components/pages/jeu/PageCarteVillage/PageActionsVillage/PageActionsVillage.vue'
import PageAttaquerVillage from '../components/pages/jeu/PageCarteVillage/PageAttaquerVillage/PageAttaquerVillage.vue'
import PageSoutenirVillage from '../components/pages/jeu/PageCarteVillage/PageSoutenirVillage/PageSoutenirVillage.vue'
import PageEspionnerVillage from '../components/pages/jeu/PageCarteVillage/PageEspionnerVillage/PageEspionnerVillage.vue'
import PageApprovisionnerVillage from '../components/pages/jeu/PageCarteVillage/PageApprovisionnerVillage/PageApprovisionnerVillage.vue'
import PageVillage from '../components/pages/jeu/PageVillage/PageVillage.vue'
import PageArmees from '../components/pages/jeu/PageArmees/PageArmees.vue'
import PageListeRapports from '../components/pages/jeu/PageListeRapports/PageListeRapports.vue'
import PageRapport from '../components/pages/jeu/PageRapport/PageRapport.vue'
import PageMessages from '../components/pages/jeu/PageMessages/PageMessages.vue'
import PageClassement from '../components/pages/jeu/PageClassement/PageClassement.vue'
import PageChateau from '../components/pages/jeu/PageChateau/PageChateau.vue'
import PageEntrepot from '../components/pages/jeu/PageEntrepot/PageEntrepot.vue'
import PageFerme from '../components/pages/jeu/PageFerme/PageFerme.vue'
import PageBatimentRessource from '../components/pages/jeu/PageBatimentRessource/PageBatimentRessource.vue'
import PageCaserne from '../components/pages/jeu/PageCaserne/PageCaserne.vue'
import PageMuraille from '../components/pages/jeu/PageMuraille/PageMuraille.vue'
import PageTaverne from '../components/pages/jeu/PageTaverne/PageTaverne.vue'
import PageMarche from '../components/pages/jeu/PageMarche/PageMarche.vue'
import PageHopital from '../components/pages/jeu/PageHopital/PageHopital.vue'

export default {
	path: '/jeu',
	name: 'jeu',
	meta: {
		titre: 'Monde',
	},
	component: PageJeu,
	children: [
		{
			path: 'village',
			name: 'jeu.village',
			meta: {
				titre: 'Village'
				
			},
			component: PageVillage
		},
		{
			path: 'village/chateau',
			name: 'jeu.chateau',
			meta: {
				titre: 'Château'
			},
			component: PageChateau
		},
		{
			path: 'village/entrepot',
			name: 'jeu.entrepot',
			meta: {
				titre: 'Entrepôt'
			},
			component: PageEntrepot
		},
		{
			path: 'village/ferme',
			name: 'jeu.ferme',
			meta: {
				titre: 'Ferme'
			},
			component: PageFerme
		},
		{
			path: 'village/camp',
			name: 'jeu.camp',
			meta: {
				titre: 'Camp de bûcherons'
			},
			component: PageBatimentRessource
		},
		{
			path: 'village/carriere',
			name: 'jeu.carriere',
			meta: {
				titre: 'Carrière'
			},
			component: PageBatimentRessource
		},
		{
			path: 'village/mine',
			name: 'jeu.mine',
			meta: {
				titre: 'Mine'
			},
			component: PageBatimentRessource
		},
		{
			path: 'village/caserne',
			name: 'jeu.caserne',
			meta: {
				titre: 'Caserne'
			},
			component: PageCaserne
		},
		{
			path: 'village/muraille',
			name: 'jeu.muraille',
			meta: {
				titre: 'Muraille'
			},
			component: PageMuraille
		},
		{
			path: 'village/taverne',
			name: 'jeu.taverne',
			meta: {
				titre: 'Taverne'
			},
			component: PageTaverne
		},
		{
			path: 'village/marche',
			name: 'jeu.marche',
			meta: {
				titre: 'Marché'
			},
			component: PageMarche
		},
		{
			path: 'village/hopital',
			name: 'jeu.hopital',
			meta: {
				titre: 'Hôpital'
			},
			component: PageHopital
		},
		{
			path: 'armees',
			name: 'jeu.armees',
			meta: {
				titre: 'Armées',
				modeReduit: true
			},
			component: PageArmees
		},
		{
			path: 'rapports',
			name: 'jeu.rapports',
			meta: {
				titre: 'Rapports',
				modeReduit: true
			},
			component: PageListeRapports
		},
		{
			path: 'rapport',
			name: 'jeu.rapport',
			meta: {
				titre: 'Rapport',
				modeReduit: true
			},
			component: PageRapport
		},
		{
			path: 'messages',
			name: 'jeu.messages',
			meta: {
				titre: 'Messages',
				modeReduit: true
			},
			component: PageMessages
		},
		{
			path: 'classement',
			name: 'jeu.classement',
			meta: {
				titre: 'Classement',
				modeReduit: true
			},
			component: PageClassement
		},
		{
			path: ':ligne/:colonne',
			meta: {},
			props: true,
			component: PageCarteVillage,
			children: [
				{
					path: '',
					name: 'jeu.carte.village',
					meta: {
						modeReduit: true
					},
					component: PageActionsVillage
				},
				{
					path: 'attaquer',
					name: 'jeu.carte.village.attaquer',
					meta: {
						modeReduit: true
					},
					component: PageAttaquerVillage
				},
				{
					path: 'soutenir',
					name: 'jeu.carte.village.soutenir',
					meta: {
						modeReduit: true
					},
					component: PageSoutenirVillage
				},
				{
					path: 'espionner',
					name: 'jeu.carte.village.espionner',
					meta: {
						modeReduit: true
					},
					component: PageEspionnerVillage
				},
				{
					path: 'approvisionner',
					name: 'jeu.carte.village.approvisionner',
					meta: {
						modeReduit: true
					},
					component: PageApprovisionnerVillage
				}
			]
		}
	]
}