import actions from './requetes/actions.js'
import authentification from './requetes/authentification.js'
import cartes from './requetes/cartes.js'
import compte from './requetes/compte.js'
import decorations from './requetes/decorations.js'
import equipes from './requetes/equipes.js'
import evenements from './requetes/evenements.js'
import messages from './requetes/messages.js'
import mondes from './requetes/mondes.js'
import ordres from './requetes/ordres.js'
import rapports from './requetes/rapports.js'
import simulateurs from './requetes/simulateurs.js'
import stats from './requetes/stats.js'
import tours from './requetes/tours.js'
import villages from './requetes/villages.js'

const serveur = {
	install(Vue) {
		Vue.prototype.$serveur = {
			actions,
			authentification,
			cartes,
			compte,
			decorations,
			equipes,
			evenements,
			messages,
			mondes,
			ordres,
			rapports,
			simulateurs,
			stats,
			tours,
			villages
		}
	}
}
export default serveur