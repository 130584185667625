import Icone from '@/components/blocs/Icone/Icone.vue'

export default {
	components: {
		Icone
	},
	props: {
		titre: String,
		iconeTitre: String,
		classesTitre: String,
		largeurTitre: Number,
		cacherAiles: Boolean,
		titreCliquable: Boolean,
		sousTitre: String,
		curseur: {
			type: String,
			default: 'default'
		},
		largeur: {
			type: Number,
			default: 380
		},
		sansContenu: Boolean,
		sansActions: Boolean,
		sansMargeInterieure: Boolean,
		noir: Boolean
	}
}