export default {
	data() {
		return {
            erreur: null
		}
	},
	props: {
		jeton: String
	},
	methods: {
        validationEmail() {
			this.$serveur.authentification.validationCompte(this.jeton).then(() => {
				this.emailEnvoye = true
				this.$router.replace('/mondes')
			}, erreur => {
				this.erreur = erreur.response.status == 400 ? erreur.response.data.message : 'Une erreur s\'est produite'
			}).finally(() => this.chargementEnvoi = false)
        }
	},
    mounted() {
        this.validationEmail()
    }
}