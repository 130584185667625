import HautDePageMessages from './HautDePageMessages/HautDePageMessages.vue'
import CreationConversation from './CreationConversation/CreationConversation.vue'
import AffichageConversations from './AffichageConversations/AffichageConversations.vue'
import AffichageMessages from './AffichageMessages/AffichageMessages.vue'

export default {
    components: {
        HautDePageMessages,
        CreationConversation,
        AffichageConversations,
        AffichageMessages
    },
	data() {
		return {
            ajout: false,
            selection: false,
            conversation: null
        }
    },
    computed: {
        conversations() {
            return this.$store.state.conversations
        }
    },
    methods: {
        afficherConversation(conversation) {
            this.selection = false
            this.ajout = false
            this.conversation = conversation
        }
    },
    mounted() {
        document.onNouvelleConversation = this.afficherConversation
        this.conversation = this.conversations[0]
    },
    destroyed() {
        document.onNouvelleConversation = null
    }
}