import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'

export default {
	components: {
		BlocAction
	},
    data () {
        return {
            chargement: false
        }
    },
    methods: {
        recharger () {
            this.chargement = true
            document.location.reload()
        }
    }
}