import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Bouton from '@/components/blocs/Bouton/Bouton.vue'
import SelectionneurUnites from '@/components/blocs/SelectionneurUnites/SelectionneurUnites.vue'
import Village from '@/classes/Village'

export default {
    components: {
        BlocAction,
        Bouton,
        SelectionneurUnites
    },
    data () {
        return {
            chargement: false,
            unites: {}
        }
    },
    props: {
        villageCible: Village,
        distance: Number,
    },
    computed: {
        village() {
            return this.$store.state.village
        },
        nombreTours() {
            const nombreToursInfanterie = Math.ceil(this.distance / 2)
            let nombreTours = 0

            if (this.unites.beliers || this.unites.catapultes) {
                nombreTours = Math.ceil(this.distance / 1.5)
                if (nombreTours == nombreToursInfanterie) {
                    nombreTours++
                }
            } else if (this.unites.guerriers || this.unites.epeistes || this.unites.archers) {
                nombreTours = nombreToursInfanterie
            } else if (this.unites.cavaliers) {
                nombreTours = Math.ceil(this.distance / 3)
                if (nombreTours == nombreToursInfanterie && nombreTours > 1) {
                    nombreTours--
                }
            }
            
            return nombreTours
        }
    },
    methods: {
        envoyer() {
            this.chargement = true
			const donnees = {
				unites: {
					guerriers: this.unites.guerriers,
					epeistes: this.unites.epeistes,
					archers: this.unites.archers,
					cavaliers: this.unites.cavaliers,
					beliers: this.unites.beliers,
                    catapultes: this.unites.catapultes
				}
            }
            
			this.$serveur.ordres.soutenirVillage(this.village.id, this.villageCible.id, donnees).then(reponse => {
				this.$store.commit('actualiserVillage', reponse.data.village)
				this.$store.commit('actualiserOrdres', reponse.data.ordres)
                this.$router.back()
			}, (erreur) => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			}).finally(() => this.chargement = false)
        }
    }
}