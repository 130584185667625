import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Icone from '@/components/blocs/Icone/Icone.vue'

export default {
	components: {
		BlocAction,
		Icone,
	},
	computed: {
		rapport() {
			return this.$store.state.rapportSelectionne
		}
	}
}