import HautDePageInterface from '@/components/blocs/HautDePageInterface/HautDePageInterface.vue'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue';
import Bouton from '@/components/blocs/Bouton/Bouton.vue';
import Carte from '@/components/blocs/Carte/Carte.vue';
import Monde from '@/classes/Monde';

export default {
	components: {
        HautDePageInterface,
		BlocAction,
		Bouton,
		Carte
	},
	data() {
		return {
			nom: null,
			nombreJoueurs: 4,
			tempsLimite: 0,
			temps: [
				{
					valeur: 0,
					nom: 'Aucune limite'
				},
				{
					valeur: 12,
					nom: '12 heures'
				},
				{
					valeur: 24,
					nom: '24 heures'
				},
				{
					valeur: 36,
					nom: '36 heures'
				},
				{
					valeur: 48,
					nom: '48 heures'
				}
			],
			afficherDialogCartes: false,
			carte: null,
			cartes: null,
			erreurs: {},
			chargementCreationMonde: false
		}
	},
	computed: {
		cartesNombreJoueurs() {
			return this.cartes ? this.cartes.filter(c => c.nombreJoueurs == this.nombreJoueurs) : []
		}
	},
	methods: {
		valider() {
			this.chargementCreationMonde = true
			const donnees = {
				nom: this.nom,
				taille: this.taille,
				nombreJoueurs: this.nombreJoueurs,
				tempsLimite: this.tempsLimite,
				idCarte: this.carte ? this.carte.id : null
			}

			this.$serveur.mondes.creation(donnees).then((reponse) => {
				this.$store.state.monde = new Monde(reponse.data.monde)
				this.$router.push({
					name: 'lobby'
				})
			}, (erreur) => {
				if (erreur.response.status == 422) {
					this.erreurs = erreur.response.data.erreurs
				} else if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			}).finally(() => {
				this.chargementCreationMonde = false
			})
		},
		getListCartesJouables() {
			this.$serveur.cartes.listeJouable().then(reponse => {
				this.cartes = reponse.data.cartes
			})
		},
        selectionnerCarte(carte) {
            this.carte = carte
			this.afficherDialogCartes = false
			this.erreurs.idCarte = null
        }
	},
	watch: {
		nombreJoueurs() {
			this.carte = null
		}
	},
	mounted() {
		this.getListCartesJouables()
	}
}
