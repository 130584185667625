import { abonnerNotifications } from'@/modules/notifications.js'
import Village from '@/classes/Village'
import Monde from '@/classes/Monde'
import RapportAttaque from '@/classes/RapportAttaque'
import RapportEspionnage from '@/classes/RapportEspionnage'
import RapportMouvementArmee from '@/classes/RapportMouvementArmee'
import RapportSoutienAttaque from '@/classes/RapportSoutienAttaque'
import RapportInformation from '@/classes/RapportInformation'
import Evenement from '@/classes/Evenement'
import Conversation from '@/classes/Conversation'
import Deconnecte from './Deconnecte/Deconnecte.vue'
import SelectionAvatar from '../pages/compte/PageMonCompte/SelectionAvatar/SelectionAvatar.vue'

const production = process.env.VUE_APP_PRODUCTION == 'true'

export default {
	components: {
		Deconnecte,
		SelectionAvatar
	},
	data() {
		return {
			notificationEtat: false,
			notificationCouleur: null,
			notificationTexte: null,
			loaded: false,
			message: null
		}
	},
	computed: {
		enligne() {
			return navigator.onLine
		}
	},
	methods: {
		ajouterNotification(texte, couleur = null) {
			this.notificationTexte = texte
			this.notificationCouleur = couleur
			this.notificationEtat = true
		},
		fermerMessage () {
			if (this.message.action) {
				this.$router.push({
					name: this.message.action.page
				})
			}
			this.message = null
		},
		setVillages(liste) {
			const villages = liste.map(contenu => new Village(contenu))

			villages.forEach(village => {
				village.utilisateur = this.$store.state.monde.utilisateurs.find(utilisateur => village.idUtilisateur == utilisateur.id)

				if (village.utilisateur) {
					village.couleur = village.utilisateur.couleur
				} else {
					village.couleur = 'grey'
				}
			})
			
			this.$store.state.villages = villages
			this.$store.state.villagesDuJoueur = villages.filter(village => village.idUtilisateur == this.$store.state.utilisateur.id)
			
			if (this.$store.state.villagesDuJoueur.length) {
				this.$store.state.village = this.$store.state.villagesDuJoueur[0]
			} else {
				this.$store.state.village = null
			}
		},
		selectionAvatar(avatar) {
			this.afficherAvatarDialog = false
			const donnees = {
				avatar
			}
			this.$serveur.compte.selectionnerAvatar(donnees).then(() => {
				this.$store.state.utilisateur.avatar = avatar
			})
		}
	},
	watch: {
		notificationEtat() {
			if (!this.notificationEtat) {
				this.notificationTexte = null
				this.notificationCouleur = null
			}
		}
	},
	mounted() {
		this.sockets.subscribe('nouveau-tour', (alerte) => {
			if (this.$store.state.monde && this.$store.state.monde.id == alerte.monde.id) {
				this.$store.state.monde = new Monde(alerte.monde)
				this.$store.state.tourTermine = false

				const rapports = alerte.rapports.attaques.map(contenu => new RapportAttaque(contenu))
					.concat(alerte.rapports.espionnages.map(contenu => new RapportEspionnage(contenu)))
					.concat(alerte.rapports.mouvementsArmees.map(contenu => new RapportMouvementArmee(contenu)))
					.concat(alerte.rapports.soutiensAttaques.map(contenu => new RapportSoutienAttaque(contenu)))
					.concat(alerte.rapports.informations.map(contenu => new RapportInformation(contenu)))

				rapports.sort(Array.triNombre(r => r.tour))

				rapports.forEach(rapport => {
					this.$store.state.rapports.unshift(rapport)
				})

				alerte.evenements.forEach(evenement => {
					this.$store.state.evenements.unshift(new Evenement(evenement))
				})

				this.$store.commit('actualiserOrdres', alerte.ordres)

				this.$store.state.joueursEnAttente = this.$store.state.monde.utilisateurs.filter(u => !u.arret)
				
				this.setVillages(alerte.monde.villages)

				if (document.onNouvellesCoordonnees) {
					document.onNouvellesCoordonnees(alerte.coordonnees)
				}

				if (this.$route.name != 'jeu') {
					if (this.$store.state.villagesDuJoueur.length) {
						if (production && this.$route.name != 'jeu.village') {
							this.$router.push({
								name: 'jeu.village'
							})
						}
					} else {
						this.$router.push({
							name: 'jeu'
						})
					}
				}
			}
		})

		this.sockets.subscribe('alerte', (alerte) => {
			if (this.$store.state.monde && this.$store.state.monde.id == alerte.idMonde) {
				this.message = alerte.message

				if (alerte.villages) {
					this.setVillages(alerte.villages)
				}

				if (alerte.action == 'abandon') {
					const index = this.$store.state.joueursEnAttente.findIndex(u => u.id == alerte.idUtilisateur)
					if (index != -1) {
						this.$store.state.joueursEnAttente.removeAt(index)
					}
				}
			}
		})

		this.sockets.subscribe('nouveau-message', (alerte) => {
			if (this.$store.state.monde && this.$store.state.monde.id == alerte.idMonde) {
				if (this.$store.state.conversations) {
					const conversation = this.$store.state.conversations.find(conversation => conversation.id == alerte.idConversation)

					if (conversation) {
						conversation.ajouterMessage(alerte.message)
					}

					if (document.onNouveauMessage) {
						this.$nextTick(() => {
							document.onNouveauMessage()
						})
					}
				}
			}
		})

		this.sockets.subscribe('nouvelle-conversation', (alerte) => {
			if (this.$store.state.monde && this.$store.state.monde.id == alerte.idMonde) {
				if (this.$store.state.conversations) {
					const conversation = new Conversation(alerte.conversation)
					this.$store.state.conversations.push(conversation)
					
					if (document.onNouvelleConversation) {
						this.$nextTick(() => {
							document.onNouvelleConversation(conversation)
						})
					}
				}
			}
		})

		this.sockets.subscribe('tour-termine', (alerte) => {
			const index = this.$store.state.joueursEnAttente.findIndex(u => u.id == alerte.idUtilisateur)
			if (index != -1) {
				this.$store.state.joueursEnAttente.removeAt(index)
			}
		})

		this.sockets.subscribe('tour-non-termine', (alerte) => {
			const utilisateur = this.$store.state.monde.utilisateurs.find(u => u.id == alerte.idUtilisateur)
			this.$store.state.joueursEnAttente.push(utilisateur)
		})

		const jeton = localStorage.getItem('jeton')
		if (jeton) {
			this.$serveur.authentification.connexionParJeton(jeton).then((reponse) => {
				this.$store.state.utilisateur = reponse.data.utilisateur
				this.$store.state.jeton = jeton

				abonnerNotifications()

				this.$socket.emit('verification-socket', reponse.data.jetonVerificationSocket)
			}, (erreur) => {
				if (erreur.response.status == 401) {
					localStorage.setItem('jeton', '')
					
					// Redirige vers la liste des mondes.
					this.$router.push({
						name: 'connexion'
					})
				}
			}).finally(() => {
				this.loaded = true
			})
		} else {
			this.loaded = true
		}
	},
	destroyed() {
		this.sockets.unsubscribe('nouveau-tour')
		this.sockets.unsubscribe('alerte')
		this.sockets.unsubscribe('nouveau-message')
		this.sockets.unsubscribe('nouvelle-conversation')
		this.sockets.unsubscribe('tour-termine')
		this.sockets.unsubscribe('tour-non-termine')
	}
}