import PageEditeurCarte from '../components/pages/cartes/PageEditeurCarte/PageEditeurCarte.vue'
import PageCartes from '../components/pages/cartes/PageCartes/PageCartes.vue'

export default {
	creation: {
		path: '/creation-carte',
		name: 'creation.carte',
		meta: {
			titre: 'Création d\'une carte'
		},
		component: PageEditeurCarte
	},
	editer: {
		path: '/editer-carte',
		name: 'editer.carte',
		meta: {
			titre: 'Editer une carte'
		},
		component: PageEditeurCarte
	},
	liste: {
		path: '/cartes',
		name: 'cartes',
		meta: {
			titre: 'Cartes'
		},
		component: PageCartes
	}
}