import Icone from '@/components/blocs/Icone/Icone.vue'

export default {
	components: {
		Icone
	},
	props: {
		nom: String,
		prixBois: Number,
		prixArgile: Number,
		prixFer: Number,
		prixVillageois: Number
	},
	computed: {
		manqueBois() {
			if (this.$store.state.village) {
				return this.prixBois > this.$store.state.village.bois
			}
			return false
		},
		manqueArgile() {
			if (this.$store.state.village) {
				return this.prixArgile > this.$store.state.village.argile
			}
			return false
		},
		manqueFer() {
			if (this.$store.state.village) {
				return this.prixFer > this.$store.state.village.fer
			}
			return false
		},
		manqueVillageois() {
			if (this.$store.state.village) {
				return this.prixVillageois > this.$store.state.village.provisions
			} 
			return false
		}
	}
}