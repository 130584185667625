const terminerTour = (idMonde) => {
	return document.axios.post('tours/terminer/' + idMonde)
}

const reprendreTour = (idMonde) => {
	return document.axios.post('tours/reprendre/' + idMonde)
}

export default {
	terminerTour,
	reprendreTour
}