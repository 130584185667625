import Icone from '@/components/blocs/Icone/Icone.vue'

export default {
	components: {
		Icone
	},
    props: {
        rapports: Array
    },
	methods: {
		selectionnerRapport(rapport) {
			this.$store.state.rapportSelectionne = rapport

			this.$router.push({
				name: 'jeu.rapport'
			})
		}
	}
}