import { unites } from 'friendswars.shared'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Bouton from '@/components/blocs/Bouton/Bouton.vue'
import AfficheurPrix from '@/components/blocs/AfficheurPrix/AfficheurPrix.vue'
import ConteneurBatiment from '@/components/blocs/ConteneurBatiment/ConteneurBatiment.vue'

export default {
	components: {
		BlocAction,
		Bouton,
		AfficheurPrix,
		ConteneurBatiment
	},
	data() {
		return {
			unite: unites.espions
		}
	},
	computed: {
		village() {
			return this.$store.state.village
		},
		espionsRecrutes() {
			return this.village.recrutements.filter(recrutement => recrutement.nomUnite == 'espions')
		},
		recrutable() {
			return this.village.bois >= this.unite.prixEnBois
				&& this.village.argile >= this.unite.prixEnArgile
				&& this.village.fer >= this.unite.prixEnFer
				&& this.village.provisions >= this.unite.villageois
		}
	},
	methods: {
		recruter() {
			const idVillage = this.$store.state.village.id
			this.$serveur.actions.recruterEspion(idVillage, this.nomUniteSelectionne + 's').then((reponse) => {
				this.$store.commit('actualiserVillage', reponse.data.village)
				this.popup = false
			}, (erreur) => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		}
	}
}