import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Bouton from '@/components/blocs/Bouton/Bouton.vue'

export default {
	components: {
		BlocAction,
		Bouton
	},
	data() {
		return {
			email: null,
			envoye: false,
			erreurs: {}
		}
	},
	methods: {
		valider() {
			const donnees = {
				email: this.email
			}
			this.$serveur.authentification.motDePasseOublie(donnees).then(() => {
				this.erreurs = {}
				this.envoye = true
			}, (erreur) => {
				if (erreur.response.status == 422) {
					this.erreurs = erreur.response.data.erreurs
				}
			})
		}
	}
}