import Vue from 'vue'
import Village from '@/classes/Village'
import Attaque from '@/classes/Attaque'
import MouvementArmee from '@/classes/MouvementArmee'
import Espionnage from '@/classes/Espionnage'
import Soutien from '@/classes/Soutien'
import MouvementRessources from '@/classes/MouvementRessources'
import EchangeRessources from '@/classes/EchangeRessources'

export default {
    actualiserVillage (state, village) {
        state.village = new Village(village, state.village.loyautes, state.village.lits)

        const indexVillages = state.villages.findIndex(v => v.id == village.id)
        Vue.set(state.villages, indexVillages, state.village)

        const indexVillagesDuJoueur = state.villagesDuJoueur.findIndex(v => v.id == village.id)
        Vue.set(state.villagesDuJoueur, indexVillagesDuJoueur, state.village)
    },
    actualiserOrdres (state, ordres) {
        state.ordres = {
            attaques: {
                externe: ordres.attaques.externe.map(contenu => new Attaque(contenu)),
                interne: {
                    aller: ordres.attaques.interne.aller.map(contenu => new Attaque(contenu)),
                    retour: ordres.attaques.interne.retour.map(contenu => new Attaque(contenu))
                }
            },
            espionnages: ordres.espionnages.map(contenu => new Espionnage(contenu)),
            mouvementsArmees: {
                externe: ordres.mouvementsArmees.externe.map(contenu => new MouvementArmee(contenu)),
                interne: ordres.mouvementsArmees.interne.map(contenu => new MouvementArmee(contenu))
            },
            soutiens: {
                externe: ordres.soutiens.externe.map(contenu => new Soutien(contenu)),
                interne: ordres.soutiens.interne.map(contenu => new Soutien(contenu))
            },
            mouvementsRessources: ordres.mouvementsRessources.map(contenu => new MouvementRessources(contenu)),
            echangesRessources: ordres.echangesRessources.map(contenu => new EchangeRessources(contenu))
        }
    }
}
