import PageAide from '../components/pages/aide/PageAide/PageAide.vue'
import PageBatiments from '../components/pages/aide/PageBatiments/PageBatiments.vue'
import PageUnites from '../components/pages/aide/PageUnites/PageUnites.vue'
import PageSimulateurBataille from '../components/pages/aide/PageSimulateurBataille/PageSimulateurBataille.vue'

export default {
	path: '/aide',
	name: 'aide',
	meta: {
		titre: 'Aide',
		sansConnexion: true
	},
	component: PageAide,
	children: [
		{
			path: '/aide/batiments/:nomBatiment',
			name: 'aide.batiments',
			meta: {
				titre: 'Aide - Batiments',
				sansConnexion: true
			},
			props: true,
			component: PageBatiments
		},
		{
			path: '/aide/unites/:nomUnite',
			name: 'aide.unites',
			meta: {
				titre: 'Aide - Unités',
				sansConnexion: true
			},
			props: true,
			component: PageUnites
		},
		{
			path: '/aide/simulateur/bataille',
			name: 'aide.simulateur.bataille',
			meta: {
				titre: 'Aide - Simulateur de bataille',
				sansConnexion: true
			},
			component: PageSimulateurBataille
		}
	]
}