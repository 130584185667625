import '@babel/polyfill'
import '@/modules/fonctions.js'
import Vue from 'vue'
import VueMoment from 'vue-moment'
import VueSocketIO from 'vue-socket.io'
import VueNumberInput from '@chenfengyuan/vue-number-input'
import VueMousetrap from 'vue-mousetrap/vue2'
import axios from 'axios'
import moment from 'moment'
import VueDragscroll from 'vue-dragscroll'
import vuetify from '@/plugins/vuetify.js'
import serveur from '@/plugins/serveur/serveur.js'
import store from '@/store'
import router from '@/routes'
import App from '@/components/App/App.vue'

moment.locale('fr')

Vue.config.productionTip = false
Vue.use(VueMoment, {
	moment
})
Vue.use(VueDragscroll)
Vue.use(VueNumberInput)
Vue.use(VueMousetrap)
Vue.use(serveur)
Vue.use(new VueSocketIO({
	connection: process.env.VUE_APP_API_URL,
	vuex: {
		store,
		actionPrefix: 'SOCKET_',
		mutationPrefix: 'SOCKET_'
	}
}))

document.axios = axios.create({
	baseURL: process.env.VUE_APP_API_URL + '/api/'
})

window.onblur = () => {
	document.focus = false
}

window.onfocus = () => {
	document.focus = true
}

// Ajout du jeton dans l'entête par défaut.
document.axios.interceptors.request.use(function (config) {
	const jeton = store.state.jeton
	if (jeton) {
		config.headers.Authorization = jeton
	}
	return config
})

// Redirige sur la page de connexion si l'utilisateur n'est pas connecté et qu'il essaie d'aller sur les pages du jeu.
router.beforeEach((pageApres, pageAvant, suivant) => {
	if (pageApres.meta.sansConnexion || store.state.utilisateur || localStorage.getItem('jeton')) {
		if (pageApres.meta.titre) {
			document.title = pageApres.meta.titre + ' – FriendsWars'
		}
		suivant()
	} else {
		suivant('/connexion')
	}
})

window.onload = () => {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.register('/sw.js')
	}
}

new Vue({
	components: {
		App
	},
	vuetify,
	store,
	router,
	render: h => h(App)
}).$mount('#app')