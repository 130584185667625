import moment from 'moment'
import store from '@/store'

export default class Message {
	constructor(contenu) {
		this.id = contenu.id
		this.utilisateur = store.state.monde.utilisateurs.find(utilisateur => utilisateur.id == contenu.idUtilisateur)
		this.contenu = contenu.contenu.replaceAll('\n', '<br>')
		this.dateCreation = moment(contenu.dateCreation)
	}
}