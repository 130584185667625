const getListeRapports = (idMonde) => {
	return document.axios.get('rapports/' + idMonde)
}

const marquerRapportAttaqueCommeVu = (id) => {
	return document.axios.post('rapports/attaque/' + id + '/vu')
}

const marquerRapportEspionnageCommeVu = (id) => {
	return document.axios.post('rapports/espionnage/' + id + '/vu')
}

const marquerRapportMouvementArmeeCommeVu = (id) => {
	return document.axios.post('rapports/mouvement-armee/' + id + '/vu')
}

const marquerRapportSoutienAttaqueCommeVu = (id) => {
	return document.axios.post('rapports/soutien-attaque/' + id + '/vu')
}

const marquerRapportInformationCommeVu = (id) => {
	return document.axios.post('rapports/information/' + id + '/vu')
}

export default {
	getListeRapports,
	marquerRapportAttaqueCommeVu,
	marquerRapportEspionnageCommeVu,
	marquerRapportMouvementArmeeCommeVu,
	marquerRapportSoutienAttaqueCommeVu,
	marquerRapportInformationCommeVu
}