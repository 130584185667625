import Avatar from '@/components/blocs/Avatar/Avatar.vue'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Bouton from '@/components/blocs/Bouton/Bouton.vue'

export default {
    components: {
        Avatar,
        BlocAction,
        Bouton
    },
    data () {
        return {
            villageCible: null,
            renommageVillage: false,
            nouveauNomVillage: null
        }
    },
    props: {
        ligne: [Number, String],
        colonne: [Number, String]
    },
    computed: {
        village() {
            return this.$store.state.village
        },
        joueurCible() {
			if (this.villageCible.idUtilisateur) {
				return this.$store.state.monde.utilisateurs.find(utilisateur => this.villageCible.idUtilisateur == utilisateur.id)
			} else {
				return null
			}
        },
        distance() {
            return Number.calculerDistance(this.village.ligne, this.village.colonne, this.villageCible.ligne, this.villageCible.colonne)
        },
        estVillageUtilisateur() {
            return this.villageCible && this.villageCible.idUtilisateur == this.$store.state.utilisateur.id
        },
        estVillageEquipe() {
            return this.villageCible &&
                this.villageCible.utilisateur &&
                this.villageCible.utilisateur.equipe.id == this.$store.state.monde.utilisateur.equipe.id
        },
    },
    methods: {
        renommerVillage() {
			const donnees = {
                nom: this.nouveauNomVillage
            }

			this.$serveur.villages.renommer(this.villageCible.id, donnees).then((reponse) => {
                this.villageCible.nom = reponse.data.village.nom
                this.renommageVillage = false
                this.nouveauNomVillage = null

                const villagesDuJoueur = this.$store.state.villages.filter(village => village.idUtilisateur == this.$store.state.utilisateur.id)
				villagesDuJoueur.sort(Array.triObjet(v => v.nom))
				this.$store.state.villagesDuJoueur = villagesDuJoueur
			}, (erreur) => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
        }
    },
    watch: {
        renommageVillage() {
            if (this.renommageVillage) {
                this.nouveauNomVillage = this.villageCible.nom

                setTimeout(() => {
                    this.$refs.nouveauNom.focus()
                }, 10)
            }
        }
    },
    mounted() {
        this.villageCible = this.$store.state.villages.find(v => v.ligne == this.ligne && v.colonne == this.colonne)

		document.title = this.villageCible.nom + ' ' + this.villageCible.position + ' – Friendswars'
    }
}