import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Bouton from '@/components/blocs/Bouton/Bouton.vue'
import Icone from '@/components/blocs/Icone/Icone.vue'
import LienVillage from '@/components/blocs/LienVillage/LienVillage.vue'
import ConteneurBatiment from '@/components/blocs/ConteneurBatiment/ConteneurBatiment.vue'
import SelectionneurRessource from '@/components/blocs/SelectionneurRessource/SelectionneurRessource.vue'

export default {
	components: {
		BlocAction,
		Bouton,
		Icone,
		LienVillage,
		ConteneurBatiment,
		SelectionneurRessource
	},
	data() {
		return {
			tab: 0,
			ressources: [
				'bois',
				'argile',
				'fer'
			],
			ressourceEnvoyee: 'bois',
			ressourceRecue: 'argile',
			ancienneRessourceEnvoyee: 'bois',
			ancienneRessourceRecue: 'argile',
			quantiteEnvoyee: 0,
			chargement: false,
		}
	},
	computed: {
		village() {
			return this.$store.state.village
		},
		monde() {
			return this.$store.state.monde
		},
		quantiteRecue() {
			return this.quantiteEnvoyee * this.monde.tauxChange * 1000
		},
		ordres () {
			return this.$store.state.ordres
		},
		mouvementsRessourcesAller() {
			return this.ordres.mouvementsRessources.filter(m => m.villageDepart.id == this.village.id && m.tourArrivee > this.monde.tour)
		},
		mouvementsRessourcesRetour() {
			return this.ordres.mouvementsRessources.filter(m => m.villageDepart.id == this.village.id && m.tourArrivee <= this.monde.tour)
		},
		echangesRessourcesAller() {
			return this.ordres.echangesRessources.filter(m => m.village.id == this.village.id && m.tourArrivee > this.monde.tour)
		},
		echangesRessourcesRetour() {
			return this.ordres.echangesRessources.filter(m => m.village.id == this.village.id && m.tourArrivee <= this.monde.tour)
		}
	},
	methods: {
		envoyer() {
			this.chargement = true
			const donnees = {
				ressourceEnvoyee: this.ressourceEnvoyee,
				ressourceRecue: this.ressourceRecue,
				quantite: this.quantiteEnvoyee
			}
			
			this.$serveur.actions.echangerRessources(this.village.id, donnees).then(reponse => {
				this.$router.back()
				this.$store.commit('actualiserVillage', reponse.data.village)
				this.$store.commit('actualiserOrdres', reponse.data.ordres)
			}, (erreur) => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			}).finally(() => this.chargement = false)
		},
		changementRessourceEnvoyee() {
			if (this.ressourceEnvoyee == this.ressourceRecue) {
				this.ressourceRecue = this.ancienneRessourceEnvoyee
				this.ancienneRessourceRecue = this.ressourceRecue
			}
			this.ancienneRessourceEnvoyee = this.ressourceEnvoyee
		},
		changementRessourceRecue() {
			if (this.ressourceEnvoyee == this.ressourceRecue) {
				this.ressourceEnvoyee = this.ancienneRessourceRecue
				this.ancienneRessourceEnvoyee = this.ressourceEnvoyee
			}
			this.ancienneRessourceRecue = this.ressourceRecue
		}
	}
}