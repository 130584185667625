import HautDePageInterface from '@/components/blocs/HautDePageInterface/HautDePageInterface.vue'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Icone from '@/components/blocs/Icone/Icone.vue'

export default {
	components: {
		HautDePageInterface,
		BlocAction,
		Icone
	},
	data() {
		return {
			// Formulaire.
			guerriersAttaquant: 0,
			guerriersDefenseur: 0,
			epeistesAttaquant: 0,
			epeistesDefenseur: 0,
			archersAttaquant: 0,
			archersDefenseur: 0,
			cavaliersAttaquant: 0,
			cavaliersDefenseur: 0,
			beliersAttaquant: 0,
			beliersDefenseur: 0,
			catapultesAttaquant: 0,
			catapultesDefenseur: 0,
			muraille: 0,

			// Pertes.
			pertesAttaquant: null,
			pertesDefenseur: null,
			pertesMuraille: null
		}
	},
	methods: {
		calculer() {
			if (this.$charge) {
				const donnees = {
					attaquant: {
						guerriers: this.guerriersAttaquant,
						epeistes: this.epeistesAttaquant,
						archers: this.archersAttaquant,
						cavaliers: this.cavaliersAttaquant,
						beliers: this.beliersAttaquant,
						catapultes: this.catapultesAttaquant
					},
					defenseur: {
						guerriers: this.guerriersDefenseur,
						epeistes: this.epeistesDefenseur,
						archers: this.archersDefenseur,
						cavaliers: this.cavaliersDefenseur,
						beliers: this.beliersDefenseur,
						catapultes: this.catapultesDefenseur
					},
					muraille: this.muraille
				}

				this.$serveur.simulateurs.bataille(donnees).then((reponse) => {
					this.pertesAttaquant = reponse.data.pertesAttaquant
					this.pertesDefenseur = reponse.data.pertesDefenseur
					this.pertesMuraille = this.muraille - reponse.data.muraille
				}, (erreur) => {
					console.error(erreur)
				})
			}
		}
	},
	mounted() {
		const pertesVides = {
			guerriers: 0,
			epeistes: 0,
			archers: 0,
			cavaliers: 0,
			beliers: 0,
			catapultes: 0
		}

		this.pertesAttaquant = pertesVides
		this.pertesDefenseur = pertesVides

		this.$charge = true
	}
}