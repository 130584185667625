import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Avatar from '@/components/blocs/Avatar/Avatar.vue'

export default {
    components: {
        BlocAction,
        Avatar
    },
    props: {
        conversations: Array,
        conversation: Object
    }
}