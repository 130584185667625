import Construction from '@/classes/Construction';
import AfficheurPrix from '@/components/blocs/AfficheurPrix/AfficheurPrix.vue'
import Bouton from '@/components/blocs/Bouton/Bouton.vue'
import BoutonAmeliorerBatiment from '@/components/blocs/BoutonAmeliorerBatiment/BoutonAmeliorerBatiment.vue'

export default {
    components: {
        AfficheurPrix,
        Bouton,
        BoutonAmeliorerBatiment
    },
    computed: {
        village() {
            return this.$store.state.village
        },
        nomBatiment () {
            return this.$route.name.split('.')[1]
        },
        batiment() {
            return this.$store.state.village[this.nomBatiment]
        },
		nombreAmeliorations() {
			return this.$store.state.village.actions.filter(action => action instanceof Construction && action.nomBatiment == this.nomBatiment).length
		}
    },
    methods: {
        retour() {
            this.$router.back()
        }
    }
}