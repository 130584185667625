import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Bouton from '@/components/blocs/Bouton/Bouton.vue'
import Icone from '@/components/blocs/Icone/Icone.vue'

const production = process.env.VUE_APP_PRODUCTION == 'true'

export default {
	components: {
		BlocAction,
		Bouton,
		Icone
	},
	data() {
		return {
			afficherMenuOptions: false,
			afficherMenuMobile: false,
			afficherConfirmationAbandon: false,
			afficher: !!this.$store.state.villages && !this.$store.state.monde.dateFin,
			version: null,
			chargement: false
		}
	},
	computed: {
		permissionNotifications() {
			let permissionNotifications
			try {
				switch (Notification.permission) {
					case 'granted':
						permissionNotifications = 'activées'
						break;
					case 'denied':
						permissionNotifications = 'désactivées'
						break;
				
					default:
						permissionNotifications = null
						break;
				}
			} catch (error) {
				permissionNotifications = null
				console.log('Notification non prise en compte');
			}
			return permissionNotifications
		},
		nombreRapportsNonVus() {
			if (this.$store.state.rapports) {
				const nombreRapportsNonVus = this.$store.state.rapports.filter(rapport => !rapport.vu).length
				return nombreRapportsNonVus
			}
			return 0
		},
		nombreMessagesNonVus() {
			let nombreMessagesNonVus = 0
			if (this.$store.state.conversations) {
				for (const conversation of this.$store.state.conversations) {
					nombreMessagesNonVus += conversation.messagesNonVus.length
				}
			}
			return nombreMessagesNonVus
		},
		nombreEvenementsNonVus() {
			if (this.$store.state.evenements) {
				return this.$store.state.evenements.filter(e => e.type != 'creation-monde' && e.id > this.$store.state.monde.utilisateur.idDernierEvenement).length
			}
		},
		nombreAlertes() {
			return (this.$store.state.ordres ? this.$store.state.ordres.attaques.externe.length : 0)
				+ this.nombreMessagesNonVus
				+ this.nombreRapportsNonVus
				+ this.nombreEvenementsNonVus
		},
		joueursEnAttente () {
			return this.$store.state.joueursEnAttente
		},
		villagesDuJoueur() {
			return this.$store.state.villagesDuJoueur
		},
		villagesDuMonde() {
			return this.$store.state.villages
		},
		village() {
			return this.$store.state.village
		},
		tourTermine() {
			return this.$store.state.tourTermine
		},
		nombreActionsRestantesVillages() {
			if (this.villagesDuJoueur) {
				return this.villagesDuJoueur.reduce((a, b) => a + b.actionsRestantes, 0)
			}
		},
		nombreTotalActions () {
			if (this.village) {
				return this.village.getNombreTotalActions()
			}
		},
		pourcentageActions() {
			if (this.villagesDuJoueur) {
				const nombreTotalActionsVillages = this.villagesDuJoueur.reduce((a, b) => a + b.getNombreTotalActions(), 0)
				const nombreActionsRestantesVillages = this.villagesDuJoueur.reduce((a, b) => a + b.actionsRestantes, 0)
				return 100 - Math.ceil(nombreActionsRestantesVillages / nombreTotalActionsVillages * 100)
			}
		},
		modeReduit() {
			return this.$route.meta.modeReduit && this.$vuetify.breakpoint.smAndDown
		},
		afficherZooms() {
			return this.$route.name == 'jeu' || this.$vuetify.breakpoint.mdAndUp
		}
	},
	methods: {
        engagerTravailleurs() {
			this.chargement = true
            this.$serveur.actions.engagerTravailleurs(this.village.id).then(reponse => {
				this.$store.commit('actualiserVillage', reponse.data.village)
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
            }).finally(() => this.chargement = false)
        },
		terminerTour() {
			if (production && this.$route.name != 'jeu') {
				this.$router.push({
					name: 'jeu'
				})
			}
			this.$serveur.tours.terminerTour(this.$store.state.monde.id).then(() => {
				this.$store.state.monde.utilisateur.tourTermine = true
				this.$store.state.tourTermine = true
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		reprendreTour () {
			this.$serveur.tours.reprendreTour(this.$store.state.monde.id).then(() => {
				this.$store.state.monde.utilisateur.tourTermine = false
				this.$store.state.tourTermine = false
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		villageSuivant() {
			if (production && this.$route.name != 'jeu' && this.$route.name != 'jeu.village') {
				this.$router.push({
					name: 'jeu'
				})
			}

			const villageSuivant = this.villagesDuJoueur.find(village => village.actionsRestantes > 0)

			if (villageSuivant) {
				this.$store.state.village = villageSuivant
			}
		},
		chargerJoueursEnAttente () {
			if (this.$store.state.monde) {
				this.$serveur.mondes.getListeUtilisateurEnAttente(this.$store.state.monde.id).then(reponse => {
					this.$store.state.joueursEnAttente = reponse.data.utilisateurs
				}, erreur => {
					if (erreur.response.status == 400) {
						this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
					}
				})
			}
		},
		abandonner () {
			this.$serveur.mondes.abandonner(this.$store.state.monde.id).then(() => {
				this.$router.push({
					name: 'mondes'
				})
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		deconnexion() {
			localStorage.setItem('jeton', '')
			this.$store.state.utilisateur = null
			this.$store.state.jeton = null
			this.$store.state.monde = null
			this.$store.state.village = null
			this.$store.state.rapports = null

			this.$router.push({
				name: 'accueil'
			})
		}
	},
	watch: {
		$route() {
			this.afficherMenuMobile = false
		},
		villagesDuMonde() {
			if (this.villagesDuMonde) {
				setTimeout(() => {
					this.afficher = true
				}, 500)
			}
		}
	},
	mounted() {
		this.chargerJoueursEnAttente()

		fetch('/version')
			.then(reponse => reponse.json())
			.then(reponse => {
				this.version = reponse.version
			})
	}
}