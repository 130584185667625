import store from '@/store'

export default class MouvementArmee {
	constructor(contenu) {
		this.id = contenu.id
		this.tourArrivee = contenu.tourArrivee
		this.type = contenu.type
		this.utilisateurDepart = store.state.monde.utilisateurs.find(u => u.id == contenu.idUtilisateurDepart)
		this.utilisateurArrivee = store.state.monde.utilisateurs.find(u => u.id == contenu.idUtilisateurArrivee)
		this.villageDepart = store.state.villages.find(v => v.id == contenu.idVillageDepart)
		this.villageArrivee = store.state.villages.find(v => v.id == contenu.idVillageArrivee)
		this.couleurDepart = this.utilisateurDepart ? this.utilisateurDepart.couleur : 'grey'
		this.couleurArrivee = this.utilisateurArrivee ? this.utilisateurArrivee.couleur : 'grey'
		
		// Unités.
		this.epeistes = contenu.epeistes
		this.archers = contenu.archers
		this.guerriers = contenu.guerriers
		this.cavaliers = contenu.cavaliers
		this.beliers = contenu.beliers
		this.catapultes = contenu.catapultes
    }
}