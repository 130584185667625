import Icone from '@/components/blocs/Icone/Icone.vue'

export default {
    components: {
        Icone
    },
    props: {
        avatar: Number,
        sansBordure: Boolean,
        xl: Boolean,
        lg: Boolean,
        md: Boolean,
        sm: Boolean,
        xs: Boolean
    },
    computed: {
        largeur() {
            if (this.xl) return 130
            if (this.lg) return 88
            if (this.md) return 46
            if (this.sm) return 36
            if (this.xs) return 30
            return 56
        }
    }
}