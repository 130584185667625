import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Bouton from '@/components/blocs/Bouton/Bouton.vue'

export default {
	components: {
		BlocAction,
		Bouton
	},
	data() {
		return {
			nom: null,
			email: null,
			motDePasse: null,
			confirmation: null,
			montrerMotDePasse: false,
			montrerConfirmation: false,
			erreurs: {}
		}
	},
	methods: {
		valider() {
			const donnees = {
				nom: this.nom,
				email: this.email,
				motDePasse: this.motDePasse,
				confirmation: this.confirmation
			}
			this.$serveur.authentification.inscription(donnees).then(() => {
				this.erreurs = {}
				this.$router.push({
					name: 'connexion'
				})
			}, (erreur) => {
				if (erreur.response.status == 422) {
					this.erreurs = erreur.response.data.erreurs
				}
			})
		}
	}
}