import Icone from '@/components/blocs/Icone/Icone.vue'

export default {
	components: {
		Icone
	},
    model: {
        prop: 'quantiteProp',
        event: 'change'
    },
    data () {
        return {
            quantite: 0
        }
    },
    props: {
        ressource: String,
        quantiteProp: Number
    },
    computed: {
        village () {
            return this.$store.state.village
        },
        marchandsRestants () {
            return this.village.marchands - this.quantite
        },
        ressourcesVillages () {
            return Math.floor(this.village[this.ressource] / 1000)
        },
        max () {
            return this.quantite + this.marchandsRestants
        }
    },
    watch: {
        quantite () {
            this.$emit('change', this.quantite)
        },
        quantiteProp () {
            this.quantite = this.quantiteProp
        }
    }
}