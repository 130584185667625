const getListeEvenements = (idMonde) => {
	return document.axios.get('/evenements/monde/' + idMonde)
}

const marquerDernierEvenementVu = (idMonde) => {
	return document.axios.post('/evenements/monde/' + idMonde + '/vu')
}

export default {
	getListeEvenements,
	marquerDernierEvenementVu
}