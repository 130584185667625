export default class RapportInformation {
	constructor(contenu) {
		this.id = contenu.id
		this.tour = contenu.tour
		this.vu = contenu.vu
		this.type = contenu.type
		this.contenu = contenu.contenu.replace(/\n/, '<br />')
		this.nom = 'information'
	}

	get iconePrincipale () {
		return 'info'
	}

	get titre () {
		return 'Tu as laissé tes sujets seuls trop longtemps'
	}
}