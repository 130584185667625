var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.village)?_c('header',{staticClass:"elevation-3 d-block d-md-flex",attrs:{"id":"haut-de-page-jeu"}},[_c('div',{attrs:{"id":"village"}},[_c('v-menu',{attrs:{"offset-y":"","attach":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"village-container",style:('background:' + _vm.couleur)},[_c('div',{staticClass:"avatar-container elevation-4"},[(_vm.$store.state.utilisateur.avatar)?_c('img',{staticClass:"avatar",attrs:{"src":'/assets/avatars/' + _vm.$store.state.utilisateur.avatar + '.png'}}):_vm._e(),_c('img',{staticClass:"avatar-border",attrs:{"src":"/assets/interface/avatar.png"}})]),_c('v-btn',_vm._g(_vm._b({staticClass:"normal nom-village sans-calque",attrs:{"color":"transparent","tile":"","elevation":"0","ripple":_vm.autresVillagesDuJoueur.length >= 1}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.$store.state.village.nom))]),(_vm.autresVillagesDuJoueur.length)?_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("mdi-chevron-down")]):_vm._e()],1)],1)]}}],null,false,3020736680)},[_c('v-list',{staticClass:"primary py-0",attrs:{"dense":""}},_vm._l((_vm.autresVillagesDuJoueur),function(village,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.changerVillage(village)}}},[_c('v-list-item-title',[_vm._v(_vm._s(village.nom))])],1)}),1)],1)],1),_c('v-spacer'),_c('div',{attrs:{"id":"numero-tour"}},[_c('div',{staticClass:"text-uppercase caption"},[_vm._v("Tour")]),_c('div',{staticClass:"overline"},[_vm._v(_vm._s(_vm.$store.state.monde.tour))])]),_c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll"}],attrs:{"id":"ressources"}},[_c('div',{staticClass:"liste-ressources"},[_c('v-tooltip',{attrs:{"bottom":"","color":"#2523246c","transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"ressource"},on),[_c('div',{staticClass:"image"},[_c('icone',{attrs:{"icone":"bois","largeur":"25px","color":"#FFF"}})],1),_c('div',{staticClass:"afficheur"},[_c('span',{class:(_vm.pourcentageBois < 100 ? 'yellow' : 'error') + '--text'},[_vm._v(_vm._s(_vm.$store.state.village.bois.formate()))]),_c('v-progress-linear',{attrs:{"height":_vm.hauteurRessourceBarre,"value":_vm.pourcentageBois,"color":_vm.pourcentageBois < 100 ? 'success' : 'error'}})],1)])]}}],null,false,1392467566)},[_c('div',[_c('b',[_vm._v("Bois")])]),_c('div',[_c('b',[_vm._v("Quantité par tour : ")]),_c('span',[_vm._v(_vm._s(_vm.$store.state.village.camp.getMontant().formate()))])]),_c('div',{class:{'error--text': _vm.$store.state.village.entrepot.getLimite() - _vm.$store.state.village.bois < _vm.$store.state.village.camp.getMontant()}},[_c('b',[_vm._v("Stockage restant : ")]),_c('span',[_vm._v(_vm._s((_vm.$store.state.village.entrepot.getLimite() - _vm.$store.state.village.bois).formate()))])])]),_c('v-tooltip',{attrs:{"bottom":"","color":"#2523246c","transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"ressource"},on),[_c('div',{staticClass:"image"},[_c('icone',{attrs:{"icone":"argile","largeur":"25px","color":"#FFF"}})],1),_c('div',{staticClass:"afficheur"},[_c('span',{class:(_vm.pourcentageArgile < 100 ? 'yellow' : 'error') + '--text'},[_vm._v(_vm._s(_vm.$store.state.village.argile.formate()))]),_c('v-progress-linear',{attrs:{"height":_vm.hauteurRessourceBarre,"value":_vm.pourcentageArgile,"color":_vm.pourcentageArgile < 100 ? 'success' : 'error'}})],1)])]}}],null,false,4175850317)},[_c('div',[_c('b',[_vm._v("Argile")])]),_c('div',[_c('b',[_vm._v("Quantité par tour : ")]),_c('span',[_vm._v(_vm._s(_vm.$store.state.village.carriere.getMontant().formate()))])]),_c('div',{class:{'error--text': _vm.$store.state.village.entrepot.getLimite() - _vm.$store.state.village.argile < _vm.$store.state.village.camp.getMontant()}},[_c('b',[_vm._v("Stockage restant : ")]),_c('span',[_vm._v(_vm._s((_vm.$store.state.village.entrepot.getLimite() - _vm.$store.state.village.argile).formate()))])])]),_c('v-tooltip',{attrs:{"bottom":"","color":"#2523246c","transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"ressource"},on),[_c('div',{staticClass:"image"},[_c('icone',{attrs:{"icone":"fer","largeur":"25px","color":"#FFF"}})],1),_c('div',{staticClass:"afficheur"},[_c('span',{class:(_vm.pourcentageFer < 100 ? 'yellow' : 'error') + '--text'},[_vm._v(_vm._s(_vm.$store.state.village.fer.formate()))]),_c('v-progress-linear',{attrs:{"height":_vm.hauteurRessourceBarre,"value":_vm.pourcentageFer,"color":_vm.pourcentageFer < 100 ? 'success' : 'error'}})],1)])]}}],null,false,3221833640)},[_c('div',[_c('b',[_vm._v("Fer")])]),_c('div',[_c('b',[_vm._v("Quantité par tour : ")]),_c('span',[_vm._v(_vm._s(_vm.$store.state.village.mine.getMontant().formate()))])]),_c('div',{class:{'error--text': _vm.$store.state.village.entrepot.getLimite() - _vm.$store.state.village.fer < _vm.$store.state.village.camp.getMontant()}},[_c('b',[_vm._v("Stockage restant : ")]),_c('span',[_vm._v(_vm._s((_vm.$store.state.village.entrepot.getLimite() - _vm.$store.state.village.fer).formate()))])])]),_c('v-tooltip',{attrs:{"bottom":"","color":"#2523246c","transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"ressource"},on),[_c('div',{staticClass:"image"},[_c('icone',{attrs:{"icone":"villageois","largeur":"25px","color":"#FFF"}})],1),_c('div',{staticClass:"afficheur"},[_c('span',{class:(_vm.pourcentageVillageois > 0 ? 'yellow' : 'error') + '--text'},[_vm._v(_vm._s(_vm.$store.state.village.provisions.formate()))]),_c('v-progress-linear',{attrs:{"height":_vm.hauteurRessourceBarre,"value":_vm.pourcentageVillageois,"color":_vm.pourcentageVillageois > 0 ? 'success' : 'error'}})],1)])]}}],null,false,1358994947)},[_c('span',[_vm._v("Villageois")])])],1)])],1):_c('header',{staticClass:"elevation-3 d-block d-md-flex spectateur",attrs:{"id":"haut-de-page-jeu"}},[_c('div',{staticClass:"text-uppercase text-center ma-auto body-1",attrs:{"id":"spectateur"}},[(_vm.monde.dateFin)?_c('span',[_vm._v("Monde terminé")]):_c('span',[_vm._v("Mode spectateur")])]),_c('div',{attrs:{"id":"numero-tour"}},[_c('div',{staticClass:"text-uppercase caption"},[_vm._v("Tour")]),_c('div',{staticClass:"overline"},[_vm._v(_vm._s(_vm.$store.state.monde.tour))])])])}
var staticRenderFns = []

export { render, staticRenderFns }