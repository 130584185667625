import ConteneurBatiment from '@/components/blocs/ConteneurBatiment/ConteneurBatiment.vue'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'

export default {
    components: {
        ConteneurBatiment,
        BlocAction
    },
    computed: {
        monde() {
            return this.$store.state.monde
        },
        village() {
            return this.$store.state.village
        },
        litsRestants() {
            return this.$store.state.village.litsRestants
        },
        limite() {
            return this.$store.state.village.hopital.getQuantiteSoignable()
        }
    }
}