import HautDePageInterface from '@/components/blocs/HautDePageInterface/HautDePageInterface.vue'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Avatar from '@/components/blocs/Avatar/Avatar.vue'

export default {
	components: {
		HautDePageInterface,
		BlocAction,
		Avatar
	}
}