import Village from '@/classes/Village'

export default {
    props: {
        village: Village,
        transparent: Boolean
    },
    computed: {
        couleur() {
            if (this.transparent) {
                return null
            }
            
            if (this.village.utilisateur) {
                return this.village.utilisateur.id != this.$store.state.utilisateur.id ? this.village.utilisateur.couleur : null
            }
            return 'grey'
        },
        texte() {
            return this.transparent || this.village.utilisateur && this.village.utilisateur.id == this.$store.state.utilisateur.id
        }
    }
}