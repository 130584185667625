import store from '@/store'

export default class RapportSoutienAttaque {
	constructor(contenu) {
		this.id = contenu.id
		this.utilisateurDepart = store.state.monde.utilisateurs.find(u => u.id == contenu.idUtilisateurDepart)
		this.utilisateurArrivee = store.state.monde.utilisateurs.find(u => u.id == contenu.idUtilisateurArrivee)
		this.villageDepart = store.state.villages.find(v => v.id == contenu.idVillageDepart)
		this.villageArrivee = store.state.villages.find(v => v.id == contenu.idVillageArrivee)
		this.couleurDepart = this.utilisateurDepart ? this.utilisateurDepart.couleur : 'grey'
		this.couleurArrivee = this.utilisateurArrivee ? this.utilisateurArrivee.couleur : 'grey'
		this.tour = contenu.tour
		this.vu = contenu.vu
		this.nom = 'soutien-attaque'

		// Unités.
		this.guerriers = contenu.guerriers
		this.epeistes = contenu.epeistes
		this.archers = contenu.archers
		this.cavaliers = contenu.cavaliers
		this.beliers = contenu.beliers
		this.catapultes = contenu.catapultes

		// Unités perdues.
		this.guerriersPerdus = contenu.guerriersPerdus
		this.epeistesPerdus = contenu.epeistesPerdus
		this.archersPerdus = contenu.archersPerdus
		this.cavaliersPerdus = contenu.cavaliersPerdus
		this.beliersPerdus = contenu.beliersPerdus
		this.catapultesPerdus = contenu.catapultesPerdus
	}

	get iconePrincipale () {
		return 'soutienAttaque'
	}

	get titre () {
		return 'Le soutien dans ' + this.villageArrivee.nom + ' ' + this.villageArrivee.position + ' a été attaqué'
	}
}