import Batiment from './Batiment.js'

export default class BatimentRessource extends Batiment {
	constructor(batiment, niveau) {
		super(batiment, niveau)
		this.montantBase = batiment.montantBase
		this.ratioMontant = batiment.ratioMontant
	}

	getMontant() {
		if (this.niveau == 0) return 0

		return this.calculerMontant(this.montantBase, this.ratioMontant, this.niveau)
	}

	getProchainMontant() {
		return this.calculerMontant(this.montantBase, this.ratioMontant, this.niveau + 1)
	}
}