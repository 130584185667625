import { batiments } from 'friendswars.shared'
import Batiment from './Batiment.js'

export default class Caserne extends Batiment {
	constructor(niveau) {
		super(batiments.caserne, niveau)
	}

	getQuantiteRecrutable() {
		if (this.niveau == 5) return 60
		else if (this.niveau >= 4) return 40
		else if (this.niveau >= 3) return 30
		return 20
	}
}