import store from '@/store'
import { batiments } from 'friendswars.shared'

export default class RapportAttaque {
	constructor(contenu) {
		this.id = contenu.id
		this.pourcentagePerteAttaquant = contenu.pourcentagePerteAttaquant
		this.pourcentagePerteDefenseur = contenu.pourcentagePerteDefenseur
		this.utilisateurAttaquant = store.state.monde.utilisateurs.find(u => u.id == contenu.idUtilisateurAttaquant)
		this.utilisateurDefenseur = store.state.monde.utilisateurs.find(u => u.id == contenu.idUtilisateurDefenseur)
		this.villageAttaquant = store.state.villages.find(v => v.id == contenu.idVillageAttaquant)
		this.villageDefenseur = store.state.villages.find(v => v.id == contenu.idVillageDefenseur)
		this.couleurAttaquant = this.utilisateurAttaquant ? this.utilisateurAttaquant.couleur : 'grey'
		this.couleurDefenseur = this.utilisateurDefenseur ? this.utilisateurDefenseur.couleur : 'grey'
		this.tour = contenu.tour
		this.attaquant = contenu.idUtilisateurAttaquant == store.state.utilisateur.id
		this.vu = contenu.vu
		this.chance = contenu.chance
		this.nom = 'attaque'
		
		// Unités envoyées par l'attaquant.
		this.guerriersAttaquant = contenu.guerriersAttaquant
		this.epeistesAttaquant = contenu.epeistesAttaquant
		this.archersAttaquant = contenu.archersAttaquant
		this.cavaliersAttaquant = contenu.cavaliersAttaquant
		this.beliersAttaquant = contenu.beliersAttaquant
		this.catapultesAttaquant = contenu.catapultesAttaquant

		// Unités perdues par l'attaquant.
		this.guerriersPerdusAttaquant = contenu.guerriersPerdusAttaquant
		this.epeistesPerdusAttaquant = contenu.epeistesPerdusAttaquant
		this.archersPerdusAttaquant = contenu.archersPerdusAttaquant
		this.cavaliersPerdusAttaquant = contenu.cavaliersPerdusAttaquant
		this.beliersPerdusAttaquant = contenu.beliersPerdusAttaquant
		this.catapultesPerdusAttaquant = contenu.catapultesPerdusAttaquant

		// Unités totales envoyées par l'attaquant.
		this.guerriersTotalAttaquant = contenu.guerriersTotalAttaquant
		this.epeistesTotalAttaquant = contenu.epeistesTotalAttaquant
		this.archersTotalAttaquant = contenu.archersTotalAttaquant
		this.cavaliersTotalAttaquant = contenu.cavaliersTotalAttaquant
		this.beliersTotalAttaquant = contenu.beliersTotalAttaquant
		this.catapultesTotalAttaquant = contenu.catapultesTotalAttaquant

		// Unités totales perdues par l'attaquant.
		this.guerriersTotalPerdusAttaquant = contenu.guerriersTotalPerdusAttaquant
		this.epeistesTotalPerdusAttaquant = contenu.epeistesTotalPerdusAttaquant
		this.archersTotalPerdusAttaquant = contenu.archersTotalPerdusAttaquant
		this.cavaliersTotalPerdusAttaquant = contenu.cavaliersTotalPerdusAttaquant
		this.beliersTotalPerdusAttaquant = contenu.beliersTotalPerdusAttaquant
		this.catapultesTotalPerdusAttaquant = contenu.catapultesTotalPerdusAttaquant

		// Unités du défenseur.
		this.guerriersDefenseur = contenu.guerriersDefenseur
		this.epeistesDefenseur = contenu.epeistesDefenseur
		this.archersDefenseur = contenu.archersDefenseur
		this.cavaliersDefenseur = contenu.cavaliersDefenseur
		this.beliersDefenseur = contenu.beliersDefenseur
		this.catapultesDefenseur = contenu.catapultesDefenseur

		// Unités perdues par le défenseur.
		this.guerriersPerdusDefenseur = contenu.guerriersPerdusDefenseur
		this.epeistesPerdusDefenseur = contenu.epeistesPerdusDefenseur
		this.archersPerdusDefenseur = contenu.archersPerdusDefenseur
		this.cavaliersPerdusDefenseur = contenu.cavaliersPerdusDefenseur
		this.beliersPerdusDefenseur = contenu.beliersPerdusDefenseur
		this.catapultesPerdusDefenseur = contenu.catapultesPerdusDefenseur

		// Unités soignées par le défenseur.
		this.guerriersSoignesDefenseur = contenu.guerriersSoignesDefenseur
		this.epeistesSoignesDefenseur = contenu.epeistesSoignesDefenseur
		this.archersSoignesDefenseur = contenu.archersSoignesDefenseur
		this.cavaliersSoignesDefenseur = contenu.cavaliersSoignesDefenseur

		// Attaque unique.
		this.uniqueAttaque = this.guerriersAttaquant == this.guerriersTotalAttaquant &&
			this.epeistesAttaquant == this.epeistesTotalAttaquant &&
			this.archersAttaquant == this.archersTotalAttaquant &&
			this.cavaliersAttaquant == this.cavaliersTotalAttaquant &&
			this.beliersAttaquant == this.beliersTotalAttaquant &&
			this.catapultesAttaquant == this.catapultesTotalAttaquant

		// Aucune unités en défense.
		this.aucuneUniteDefenseur = !this.guerriersDefenseur &&
			!this.epeistesDefenseur &&
			!this.archersDefenseur &&
			!this.cavaliersDefenseur &&
			!this.beliersDefenseur &&
			!this.catapultesDefenseur

		// Bâtiments ciblés par béliers et catapultes.
		this.batimentsCibles = Object.keys(batiments)
			.map(nomBatiment => {
				return {
					avant: contenu[nomBatiment + 'Avant'],
					apres: contenu[nomBatiment + 'Apres'],
					batiment: batiments[nomBatiment],
					nomBatiment
				}
			})
			.filter(b => b.avant)

		// Ressources pillées.
		this.bois = contenu.bois
		this.argile = contenu.argile
		this.fer = contenu.fer
	}

	get iconePrincipale () {
		return this.attaquant ? 'armees' : 'soutien'
	}

	get iconeSecondaire () {
		let icone
		const pourcentagePerte = this.attaquant ? this.pourcentagePerteAttaquant : this.pourcentagePerteDefenseur
		
		if (pourcentagePerte < 10) {
			icone = 'drapeau'
		} else if (pourcentagePerte == 100) {
			icone = 'crane'
		} else {
			icone = 'lameSang'
		}

		return icone
	}

	get titre () {
		return this.villageAttaquant.nom + ' attaque ' + this.villageDefenseur.nom + ' ' + this.villageDefenseur.position
	}
}