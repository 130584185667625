import Batiment from '@/classes/Batiment'
import Construction from '@/classes/Construction'
import Bouton from '@/components/blocs/Bouton/Bouton.vue'

export default {
	components: {
		Bouton
	},
	data () {
		return {
			chargement: false
		}
	},
    props: {
        nomBatiment: String,
        batiment: Batiment
    },
	computed: {
		village() {
			return this.$store.state.village
		},
		niveauxFinaux() {
			const niveaux = {}

			Object.entries(this.village.batiments).forEach(([nomBatiment, batiment]) => {
				niveaux[nomBatiment] = batiment.niveau
			})

			this.constructions
				.forEach(action => {
					niveaux[action.nomBatiment]++
				})

			return niveaux
		},
		constructions() {
			return this.village.actions.filter(action => action instanceof Construction)
		}
	},
	methods: {
		estAmeliorable(nomBatiment, batiment) {
			if (!this.village) {
				return false
			}

			if (this.village.actionsRestantes == 0) {
				return false
			}

			if (this.constructions.filter(c => c.nomBatiment == nomBatiment).length) {
				return false
			}

			return batiment.niveauChateauRequis <= this.village.chateau.niveau
				&& batiment.getPrixEnBois(true) <= this.village.bois
				&& batiment.getPrixEnArgile(true) <= this.village.argile
				&& batiment.getPrixEnFer(true) <= this.village.fer
				&& batiment.getPrixEnVillageois(true) <= this.village.provisions
		},
		ameliorerBatiment(nomBatiment) {
			this.chargement = true
			this.$serveur.actions.ameliorerBatiment(this.village.id, nomBatiment).then(reponse => {
				this.$store.commit('actualiserVillage', reponse.data.village)
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			}).finally(() => this.chargement = false)
		}
	}
}