import { batiments } from 'friendswars.shared'
import Batiment from './Batiment.js'

export default class Hopital extends Batiment {
	constructor(niveau) {
		super(batiments.hopital, niveau)

		this.litsBase = batiments.hopital.litsBase
		this.ratioLits = batiments.hopital.ratioLits
	}
	
	getQuantiteSoignable() {
		let montant = 0

		for (let niveau = 1; niveau <= this.niveau; niveau++) {
			montant += this.calculerMontant(this.litsBase, this.ratioLits, niveau)
		}

		return montant
	}

	getProchaineQuantiteSoignable() {
		return this.getQuantiteSoignable() + this.calculerMontant(this.limiteBase, this.ratioLimite, this.niveau + 1)
	}
}