import { render, staticRenderFns } from "./MenuJeu.vue?vue&type=template&id=29f9b340&"
import script from "./MenuJeu.js?vue&type=script&lang=js&"
export * from "./MenuJeu.js?vue&type=script&lang=js&"
import style0 from "./MenuJeu.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports