import store from '@/store'

export default class Espionnage {
	constructor(contenu) {
		this.id = contenu.id
		this.tourDepart = contenu.tourDepart
		this.tourArrivee = contenu.tourArrivee
		this.tourRetour = contenu.tourRetour
		this.utilisateurAttaquant = store.state.monde.utilisateurs.find(u => u.id == contenu.idUtilisateurAttaquant)
		this.utilisateurDefenseur = store.state.monde.utilisateurs.find(u => u.id == contenu.idUtilisateurDefenseur)
		this.villageAttaquant = store.state.villages.find(v => v.id == contenu.idVillageAttaquant)
		this.villageDefenseur = store.state.villages.find(v => v.id == contenu.idVillageDefenseur)
		this.couleurAttaquant = this.utilisateurAttaquant ? this.utilisateurAttaquant.couleur : 'grey'
		this.couleurDefenseur = this.utilisateurDefenseur ? this.utilisateurDefenseur.couleur : 'grey'
		this.espions = contenu.espions
    }
}