import { unites } from 'friendswars.shared'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Bouton from '@/components/blocs/Bouton/Bouton.vue'
import Icone from '@/components/blocs/Icone/Icone.vue'
import LienVillage from '@/components/blocs/LienVillage/LienVillage.vue'
import AfficheurRapports from '@/components/blocs/AfficheurRapports/AfficheurRapports.vue'
import Village from '@/classes/Village'

export default {
	components: {
        BlocAction,
        Bouton,
        Icone,
		LienVillage,
		AfficheurRapports
	},
	data () {
		return {
			unites,
			unitesSansEspion: Object.fromEntries(Object.entries(unites).filter(([nom]) => nom != 'espions')),
		}
	},
    props: {
        villageCible: Village,
        distance: Number
    },
    computed: {
        village() {
            return this.$store.state.village
        },
        ordreSoldatsPossible() {
            return !this.$store.state.tourTermine && this.village.getVillageoisUnitesCaserne() > 0
        },
        ordreEspionsPossible() {
            return !this.$store.state.tourTermine && this.village.espions > 0
        },
        ordreMarchandsPossible() {
            return !this.$store.state.tourTermine && this.village.marchands > 0
        },
        estVillageUtilisateur() {
            return this.villageCible && this.villageCible.idUtilisateur == this.$store.state.utilisateur.id
        },
        estVillageEquipe() {
            return this.villageCible &&
                this.villageCible.utilisateur &&
                this.villageCible.utilisateur.equipe.id == this.$store.state.monde.utilisateur.equipe.id
        },
        soutiensEnvoyes() {
            if (this.$store.state.ordres.soutiens) {
                return this.$store.state.ordres.soutiens.interne.filter(soutien => soutien.villageArrivee.id == this.villageCible.id)
            }
        },
		mouvementsInterne() {
            if (this.$store.state.ordres.attaques && this.$store.state.ordres.mouvementsArmees) {
                const attaquesAller = this.$store.state.ordres.attaques.interne.aller
                    .filter(attaque => attaque.villageDefenseur.id == this.villageCible.id)
                    .map(attaque => {
                        attaque.aller = true
                        attaque.tour = attaque.tourArrivee
                        return attaque
                    })
                const attaquesRetour = this.$store.state.ordres.attaques.interne.retour
                    .filter(attaque => attaque.villageDefenseur.id == this.villageCible.id)
                    .map(attaque => {
                        attaque.aller = false
                        attaque.tour = attaque.tourRetour
                        return attaque
                    })
                
                const mouvementsInterne = this.$store.state.ordres.mouvementsArmees.interne
                    .filter(mouvement => mouvement.villageArrivee.id == this.villageCible.id)
                    .map(mouvement => {
                        mouvement.aller = true
                        mouvement.tour = mouvement.tourArrivee
                        return mouvement
                    })
                
                const espionnages = this.$store.state.ordres.espionnages
                    .filter(espionnage => espionnage.villageDefenseur.id == this.villageCible.id)
                    .map(espionnage => {
                        espionnage.aller = this.$store.state.monde.tour < espionnage.tourArrivee
                        espionnage.tour = espionnage.aller ? espionnage.tourArrivee : espionnage.tourRetour
                        return espionnage
                    })

                const attaques = attaquesAller.concat(attaquesRetour)
                attaques.sort(Array.triNombre(e => e.tour))

                mouvementsInterne.sort(Array.triNombre(e => e.tour))
                espionnages.sort(Array.triNombre(e => e.tour))

                return {
                    attaques,
                    soutiens: mouvementsInterne,
                    espionnages
                }
            }
		},
        rapports() {
            const rapports = this.$store.state.rapports.filter(rapport => 
                (
                    rapport.nom == 'attaque' ||
                    rapport.nom == 'espionnage'
                ) &&
                (
                    rapport.villageDefenseur &&
                    rapport.villageDefenseur.position == this.villageCible.position
                ) ||
                (
                    rapport.villageAttaquant &&
                    rapport.villageAttaquant.position == this.villageCible.position
                )
            )

            if (rapports.length > 5) {
                rapports.length = 5
            }

            return rapports
        }
    },
    methods: {
        changerVillage() {
            this.$store.state.village = this.villageCible
            this.$router.push({
                name: 'jeu'
            })
        },
        getNombreTours(nomUnite) {
            const nombreToursInfanterie = Math.ceil(this.distance / 2)
            let nombreTours = 0

            if (['beliers', 'catapultes'].includes(nomUnite)) {
                nombreTours = Math.ceil(this.distance / 1.5)
                if (nombreTours == nombreToursInfanterie) {
                    nombreTours++
                }
            } else if (['guerriers', 'epeistes', 'archers'].includes(nomUnite)) {
                nombreTours = nombreToursInfanterie
            } else if (['cavaliers', 'espions'].includes(nomUnite)) {
                nombreTours = Math.ceil(this.distance / 3)
                if (nombreTours == nombreToursInfanterie && nombreTours > 1) {
                    nombreTours--
                }
            } else if (nomUnite == 'marchands') {
                nombreTours = Math.ceil(this.distance / 6)
            }
            
            return nombreTours
        }
    }
}