import ConteneurBatiment from '@/components/blocs/ConteneurBatiment/ConteneurBatiment.vue'

export default {
    components: {
        ConteneurBatiment
    },
    data () {
        return {
            
        }
    },
    computed: {
        limiteActuelle() {
            return this.$store.state.village.entrepot.getLimite()
        },
        prochaineLimite() {
            return this.$store.state.village.entrepot.getProchaineLimite()
        }
    }
}