import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import SelectionneurRessources from '@/components/blocs/SelectionneurRessources/SelectionneurRessources.vue'
import Village from '@/classes/Village'

export default {
    components: {
        BlocAction,
        SelectionneurRessources
    },
    data () {
        return {
            chargement: false,
            ressources: {
                bois: 0,
                argile: 0,
                fer: 0
            }
        }
    },
    props: {
        villageCible: Village,
        distance: Number,
    },
    computed: {
        village() {
            return this.$store.state.village
        },
        nombreTours() {
            return Math.ceil(this.distance / 6)
        }
    },
    methods: {
        envoyer() {
            this.chargement = true
			const donnees = {
				bois: this.ressources.bois,
				argile: this.ressources.argile,
				fer: this.ressources.fer
            }
            
			this.$serveur.ordres.approvisionnerVillage(this.village.id, this.villageCible.id, donnees).then(reponse => {
                this.$router.back()
				this.$store.commit('actualiserVillage', reponse.data.village)
				this.$store.commit('actualiserOrdres', reponse.data.ordres)
			}, (erreur) => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			}).finally(() => this.chargement = false)
        }
    }
}