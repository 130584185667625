export default class Batiment {
	constructor(batiment, niveau) {
		this.nom = batiment.nom.premiereLettreMajuscule()
		this.niveau = niveau
		this.niveauChateauRequis = batiment.niveauChateauRequis
		this.niveauMaximum = batiment.niveauMaximum
		this.prixEnBoisBase = batiment.prixEnBoisBase
		this.prixEnArgileBase = batiment.prixEnArgileBase
		this.prixEnFerBase = batiment.prixEnFerBase
		this.ratioPrix = batiment.ratioPrix
		this.prixEnVillageoisBase = batiment.prixEnVillageoisBase
		this.ratioPrixVillageois = batiment.ratioPrixVillageois
		this.pointsBase = batiment.pointsBase
		this.ratioPoints = batiment.ratioPoints
		this.defenseBase = batiment.defenseBase
		this.ratioDefense = batiment.ratioDefense
	}

	getPrixEnBois(prochainNiveau = false) {
		return this.calculerMontant(this.prixEnBoisBase, this.ratioPrix, this.niveau + (prochainNiveau ? 1 : 0))
	}

	getPrixEnArgile(prochainNiveau = false) {
		return this.calculerMontant(this.prixEnArgileBase, this.ratioPrix, this.niveau + (prochainNiveau ? 1 : 0))
	}

	getPrixEnFer(prochainNiveau = false) {
		return this.calculerMontant(this.prixEnFerBase, this.ratioPrix, this.niveau + (prochainNiveau ? 1 : 0))
	}

	getPrixEnVillageois(prochainNiveau = false) {
		return this.calculerMontant(this.prixEnVillageoisBase, this.ratioPrixVillageois, this.niveau + (prochainNiveau ? 1 : 0))
			- this.calculerMontant(this.prixEnVillageoisBase, this.ratioPrixVillageois, this.niveau - 1 + (prochainNiveau ? 1 : 0))
	}

	getVillageois(prochainNiveau = false) {
		return this.calculerMontant(this.prixEnVillageoisBase, this.ratioPrixVillageois, this.niveau + (prochainNiveau ? 1 : 0))
	}

	getPoints(prochainNiveau = false) {
		return this.calculerMontant(this.pointsBase, this.ratioPoints, this.niveau + (prochainNiveau ? 1 : 0))
	}

	getDefense() {
		let montant = 0

		for (let niveau = 1; niveau <= this.niveau; niveau++) {
			montant += this.calculerMontant(this.defenseBase, this.ratioDefense, niveau)
		}

		return montant
	}
	
	getDefense2() {
		return (
				this.calculerMontant(this.defenseBase, this.ratioDefense, this.niveau - 1) * (this.niveau - 1)
				+ this.calculerMontant(this.defenseBase, this.ratioDefense, this.niveau)
			)
			.arrondi()
	}

	calculerMontant(montantBase, ratio, n) {
		if (n == 0) return 0

		const valeur = (montantBase * (ratio ** (n - 1)))
		
		return valeur.arrondi()
	}
}