import HautDePageInterface from '@/components/blocs/HautDePageInterface/HautDePageInterface.vue'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Avatar from '@/components/blocs/Avatar/Avatar.vue'
import Carte from '@/components/blocs/Carte/Carte.vue'
import FormulaireEquipe from './FormulaireEquipe/FormulaireEquipe.vue'
import Monde from '@/classes/Monde'
import Utilisateur from '@/classes/Utilisateur'
import Village from '@/classes/Village'
import Equipe from '@/classes/Equipe'

export default {
	components: {
        HautDePageInterface,
		BlocAction,
		Avatar,
		FormulaireEquipe,
		Carte
	},
	data () {
		return {
			chargementDemarrageMonde: false,
			chargementGenerationCarte: false,
			afficherDialogSuppression: false,
			afficherDialogFormulaireEquipe: false,
			carte: null,
			villages: [],
			couleurs: [
				'#F44336', // Rouge
				'#2A8AD6', // Bleu
				'#4CAF50', // Vert
				'#FFC107', // Jaune
				'#821B94', // Violet
				'#00796B', // Bleu canard
				'#E91E63', // Rose
				'#B71C1C', // Rouge foncé
				'#C0CA33', // Vert clair
				'#2943d1', // Bleu foncé
				'#C175B9', // Violet pale
				'#80DEEA', // Bleu Cyan
			]
		}
	},
	computed: {
		monde() {
			return this.$store.state.monde
		},
		utilisateur () {
			return this.monde.utilisateurs.find(u => u.id == this.$store.state.utilisateur.id)
		},
		autresEquipes () {
			return this.monde.equipes.filter(e => e.id != this.utilisateur.equipe.id)
		},
		peutChangerEquipe () {
			return this.autresEquipes.length >= 2 || this.utilisateur.equipe.utilisateurs.length >= 2
		},
		estLeCreateur() {
			return this.monde.idCreateur == this.$store.state.utilisateur.id
		},
		nombrePlacesLibres() {
			return (this.monde.nombreJoueurs - this.monde.utilisateurs.length)
		},
		couleursUtilisees () {
			return this.monde.utilisateurs.map(u => u.couleur)
		}
	},
	methods: {
		chargerCarte() {
			this.$serveur.cartes.getCarteDuMonde(this.$store.state.monde.id).then((reponse) => {
				this.carte = reponse.data.carte
				this.villages = reponse.data.villages.map(village => new Village(village))
			})
		},
		demarrerMonde() {
			this.chargementDemarrageMonde = true
			this.$serveur.mondes.demarrer(this.monde.id).then(() => {}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			}).finally(() => {
				this.chargementDemarrageMonde = false
			})
		},
		supprimerMonde() {
			this.$serveur.mondes.supprimer(this.monde.id).then(() => {
				this.afficherDialogSuppression = false
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		changerEquipe(equipe) {
			this.$serveur.equipes.changer(this.monde.id, equipe.id).then(() => {
				const equipePrecedente = this.utilisateur.equipe
				this.utilisateur.equipe.utilisateurs.remove(this.utilisateur)
				if (this.utilisateur.equipe.utilisateurs.length == 1) {
					this.utilisateur.equipe.nom = this.utilisateur.equipe.utilisateurs[0].nom
				}
				this.utilisateur.equipe = equipe
				this.utilisateur.equipe.utilisateurs.push(this.utilisateur)
				if (!equipePrecedente.utilisateurs.length) {
					this.monde.equipes = this.monde.equipes.filter(e => e.id != equipePrecedente.id)
				}
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		quitterEquipe() {
			this.$serveur.equipes.quitter(this.monde.id).then(() => {
				const equipePrecedente = this.utilisateur.equipe
				this.utilisateur.equipe.utilisateurs.remove(this.utilisateur)
				if (this.utilisateur.equipe.utilisateurs.length == 1) {
					this.utilisateur.equipe.nom = this.utilisateur.equipe.utilisateurs[0].nom
				}
				this.utilisateur.equipe = new Equipe({
					nom: this.utilisateur.nom
				})
				this.utilisateur.equipe.utilisateurs.push(this.utilisateur)
				if (!equipePrecedente.utilisateurs.length) {
					this.monde.equipes = this.monde.equipes.filter(e => e.id != equipePrecedente.id)
				}
			}, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			})
		},
		actionEquipe (nom) {
			this.utilisateur.equipe.nom = nom
			this.afficherDialogFormulaireEquipe = false
		},
		changerCouleur (couleur) {
			const donnees = {
				couleur
			}
			this.$serveur.mondes.changerCouleur(this.monde.id, donnees)
		},
		choisirPosition(village) {
			if (!village.idUtilisateur || village.idUtilisateur == this.utilisateur.id) {
				this.$serveur.cartes.choisirPosition(this.monde.id, village.id).then(reponse => {
					let village = this.villages.find(v => v.idUtilisateur == this.utilisateur.id)
					if (village) {
						village.idUtilisateur = null
						village.utilisateur = null
					}

					if (reponse.data.position) {
						village = this.villages.find(v => v.id == reponse.data.position)
						if (village) {
							village.idUtilisateur = this.utilisateur.id
							village.utilisateur = this.utilisateur
						}
					}
				}, erreur => {
					if (erreur.response.status == 400) {
						this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
					} else {
						this.$root.$children[0].ajouterNotification('Une erreur est survenue.', 'error')
					}
				})
			}
		}
	},
	mounted() {
		if (this.monde) {
			this.chargerCarte()

			// Rejoins le salon du monde pour recevoir les évènements.
			this.$socket.emit('salon:rejoindre', 'monde-' + this.monde.id)

			// Lorsqu'un joueur rejoint le monde.
			this.sockets.subscribe('monde:rejoindre', alerte => {
				const utilisateur = new Utilisateur(alerte.utilisateur)
				const equipe = new Equipe(alerte.equipe)

				utilisateur.equipe = equipe
				equipe.utilisateurs.push(utilisateur)

				this.monde.utilisateurs.push(utilisateur)
				this.monde.equipes.push(equipe)
			})

			// Lorsque le monde est modifié.
			this.sockets.subscribe('monde:modification', monde => {
				this.$store.state.monde = new Monde(monde)
			})

			// Lorsque le monde est demarré.
			this.sockets.subscribe('monde:demarrage', monde => {
				this.$store.state.monde = new Monde(monde)

				// Affichage de la page du jeu.
				this.$router.replace({
					name: 'jeu'
				})
			})

			// Lorsqu'une équipe est créée.
			this.sockets.subscribe('equipe:creation', alerte => {
				const utilisateur = this.monde.utilisateurs.find(u => u.id == alerte.idUtilisateur)
				const equipe = new Equipe(alerte.equipe)
				utilisateur.equipe.utilisateurs.remove(utilisateur)
				utilisateur.equipe = equipe
				this.monde.equipes.push(equipe)
			})

			// Lorsqu'un joueur change d'équipe.
			this.sockets.subscribe('equipe:changement', alerte => {
				const utilisateur = this.monde.utilisateurs.find(u => u.id == alerte.idUtilisateur)
				const equipe = this.monde.equipes.find(e => e.id == alerte.idEquipe)
				const equipePrecedente = utilisateur.equipe
				utilisateur.equipe.utilisateurs.remove(utilisateur)
				utilisateur.equipe = equipe
				utilisateur.equipe.utilisateurs.push(utilisateur)
				if (!equipePrecedente.utilisateurs.length) {
					this.monde.equipes = this.monde.equipes.filter(e => e.id != equipePrecedente.id)
				}
			})

			// Lorsqu'une équipe est renommée.
			this.sockets.subscribe('equipe:renommage', alerte => {
				const equipe = this.monde.equipes.find(e => e.id == alerte.idEquipe)
				equipe.nom = alerte.nom
			})

			// Lorsqu'un utilisateur change de couleur.
			this.sockets.subscribe('couleur:changement', alerte => {
				const utilisateur = this.monde.utilisateurs.find(u => u.id == alerte.idUtilisateur)
				utilisateur.couleur = alerte.couleur
			})

			// Lorsqu'un utilisateur change de couleur.
			this.sockets.subscribe('position:changement', alerte => {
				let village = this.villages.find(v => v.idUtilisateur == alerte.idUtilisateur)
				if (village) {
					village.idUtilisateur = null
					village.utilisateur = null
				}

				if (alerte.position) {
					village = this.villages.find(v => v.id == alerte.position)
					if (village) {
						village.idUtilisateur = alerte.idUtilisateur
						village.utilisateur = this.monde.utilisateurs.find(u => u.id == alerte.idUtilisateur)
					}
				}
			})
		}
	},
	destroyed() {
		if (this.monde) {
			// Quitte le salon du monde.
			this.$socket.emit('salon:quitter', 'monde-' + this.monde.id)

			// Se désabonne des évènements du monde.
			this.sockets.unsubscribe('monde:rejoindre')
			this.sockets.unsubscribe('monde:modification')
			this.sockets.unsubscribe('monde:demarrage')
			this.sockets.unsubscribe('equipe:changement')
			this.sockets.unsubscribe('equipe:renommage')
			this.sockets.unsubscribe('couleur:changement')
			this.sockets.unsubscribe('position:changement')
		}
	},
	beforeCreate() {
		// Si aucun monde n'est sélectionné, redirige vers la list des mondes.
		// Sinon, si le monde sélectionné est déjà démarré, redirige vers la page du jeu.
		if (!this.$store.state.monde) {
			this.$router.replace({
				name: 'mondes'
			})
		} else if (this.$store.state.monde.dateLancement) {
			this.$router.replace({
				name: 'jeu'
			})
		}
	}
}