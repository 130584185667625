import { unites } from 'friendswars.shared'
import HautDePageInterface from '@/components/blocs/HautDePageInterface/HautDePageInterface.vue'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Icone from '@/components/blocs/Icone/Icone.vue'
import Chateau from '@/classes/Chateau'
import Entrepot from '@/classes/Entrepot'
import Ferme from '@/classes/Ferme'
import Camp from '@/classes/Camp'
import Carriere from '@/classes/Carriere'
import Mine from '@/classes/Mine'
import Caserne from '@/classes/Caserne'
import Muraille from '@/classes/Muraille'
import Marche from '@/classes/Marche'
import Taverne from '@/classes/Taverne'
import Hopital from '@/classes/Hopital'

export default {
	components: {
        HautDePageInterface,
		BlocAction,
		Icone
	},
	data() {
		return {
			menu: this.$vuetify.breakpoint.lgAndUp,
			batiments: {
				chateau: new Chateau(0),
				entrepot: new Entrepot(0),
				ferme: new Ferme(0),
				camp: new Camp(0),
				carriere: new Carriere(0),
				mine: new Mine(0),
				caserne: new Caserne(0),
				muraille: new Muraille(0),
				marche: new Marche(0),
				taverne: new Taverne(0),
				hopital: new Hopital(0)
			},
			unites
		}
	},
	mounted() {
		if (this.$route.name == 'aide') {
			this.$router.replace({
				name: 'aide.batiments',
				params: {
					nomBatiment: 'chateau'
				}
			})
		}
	}
}