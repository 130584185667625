import { batiments } from 'friendswars.shared'
import Batiment from './Batiment.js'

export default class Ferme extends Batiment {
	constructor(niveau) {
		super(batiments.ferme, niveau)

		this.limiteBase = batiments.ferme.limiteBase
		this.ratioLimite = batiments.ferme.ratioLimite
	}

	getLimite() {
		let montant = 0

		for (let niveau = 1; niveau <= this.niveau; niveau++) {
			montant += this.calculerMontant(this.limiteBase, this.ratioLimite, niveau)
		}

		return montant
	}

	getProchaineLimite() {
		return this.getLimite() + this.calculerMontant(this.limiteBase, this.ratioLimite, this.niveau + 1)
	}
}