import PageInscription from '../components/pages/authentification/PageInscription/PageInscription.vue'
import PageConnexion from '../components/pages/authentification/PageConnexion/PageConnexion.vue'
import PageMotDePasseOublie from '../components/pages/authentification/PageMotDePasseOublie/PageMotDePasseOublie.vue'
import PageReinitialisationMotDePasse from '../components/pages/authentification/PageReinitialisationMotDePasse/PageReinitialisationMotDePasse.vue'
import PageValidationCompte from '../components/pages/authentification/PageValidationCompte/PageValidationCompte.vue'

export default {
	inscription: {
		path: '/inscription',
		name: 'inscription',
		meta: {
			titre: 'Inscription',
			sansConnexion: true
		},
		component: PageInscription
	},
	connexion: {
		path: '/connexion',
		name: 'connexion',
		meta: {
			titre: 'Connexion',
			sansConnexion: true
		},
		component: PageConnexion
	},
	motDePasseOublie: {
		path: '/mot-de-passe-oublie',
		name: 'mot-de-passe-oublie',
		meta: {
			titre: 'Mot de passe oublié',
			sansConnexion: true
		},
		component: PageMotDePasseOublie
	},
	reinitialisationMotDePasse: {
		path: '/reinitialiser-mot-de-passe/:jeton',
		name: 'reinitialiser-mot-de-passe',
		meta: {
			titre: 'Réinitialisation du mot de passe',
			sansConnexion: true
		},
		props: true,
		component: PageReinitialisationMotDePasse
	},
	validationCompte: {
		path: '/compte/validation/:jeton',
		name: 'validation-compte',
		meta: {
			titre: 'Validation du compte',
			sansConnexion: true
		},
		props: true,
		component: PageValidationCompte
	}
}