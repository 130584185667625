import AfficheurRapports from '@/components/blocs/AfficheurRapports/AfficheurRapports.vue'

export default {
	components: {
		AfficheurRapports
	},
	computed: {
		rapports() {
			return this.$store.state.rapports
		},
		monde() {
			return this.$store.state.monde
		},
		composantRapportSelectionne() {
			if (this.rapportSelectionne) {
				return 'details-rapport-' + this.rapportSelectionne.nom
			}
		}
	},
	watch: {
		rapportSelectionne() {
			this.$parent.$el.scrollTop = 0

			if (this.$parent.update) {
				this.$parent.update()
			}
		}
	}
}