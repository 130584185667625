import Monde from '@/components/icons/Monde.vue';
import Armees from '@/components/icons/Armees.vue';
import Rapport from '@/components/icons/Rapport.vue';
import Message from '@/components/icons/Message.vue';
import Classement from '@/components/icons/Classement.vue';
import Parametre from '@/components/icons/Parametre.vue';
import Travailleurs from '@/components/icons/Travailleurs.vue';

import Chateau from '@/components/icons/Chateau.vue';
import Muraille from '@/components/icons/Muraille.vue';
import Entrepot from '@/components/icons/Entrepot.vue';
import Camp from '@/components/icons/Camp.vue';
import Carriere from '@/components/icons/Carriere.vue';
import Mine from '@/components/icons/Mine.vue';
import Ferme from '@/components/icons/Ferme.vue';
import Caserne from '@/components/icons/Caserne.vue';
import Taverne from '@/components/icons/Taverne.vue';
import Marche from '@/components/icons/Marche.vue';
import Hopital from '@/components/icons/Hopital.vue';

import Guerriers from '@/components/icons/Guerriers.vue';
import Epeistes from '@/components/icons/Epeistes.vue';
import Archers from '@/components/icons/Archers.vue';
import Cavaliers from '@/components/icons/Cavaliers.vue';
import Catapultes from '@/components/icons/Catapultes.vue';
import Beliers from '@/components/icons/Beliers.vue';
import Espions from '@/components/icons/Espions.vue';
import Marchands from '@/components/icons/Marchands.vue';
import MarchandsRetour from '@/components/icons/MarchandsRetour.vue';
import Barbare from '@/components/icons/Barbare.vue';

import CoffrePlein from '@/components/icons/CoffrePlein.vue';
import CoffreVide from '@/components/icons/CoffreVide.vue';

import Bois from '@/components/icons/Bois.vue';
import Argile from '@/components/icons/Argile.vue';
import Fer from '@/components/icons/Fer.vue';
import Villageois from '@/components/icons/Villageois.vue';

import Defense from '@/components/icons/Defense.vue';
import Soutien from '@/components/icons/Soutien.vue';
import SoutienAttaque from '@/components/icons/SoutienAttaque.vue';
import SoutienEpee from '@/components/icons/SoutienEpee.vue';
import BouclierEpee from '@/components/icons/BouclierEpee.vue';

import Info from '@/components/icons/Info.vue';
import Aller from '@/components/icons/Aller.vue';
import Retour from '@/components/icons/Retour.vue';
import LameSang from '@/components/icons/LameSang.vue';
import Crane from '@/components/icons/Crane.vue';
import Drapeau from '@/components/icons/Drapeau.vue';

export default {
    components: {
		Monde,
    Armees,
    Rapport,
    Message,
    Classement,
    Parametre,
    Travailleurs,

    Chateau,
    Muraille,
    Entrepot,
    Camp,
    Carriere,
    Mine,
    Ferme,
    Caserne,
    Taverne,
    Marche,
    Hopital,

    Guerriers,
    Epeistes,
    Archers,
    Cavaliers,
    Catapultes,
    Beliers,
    Espions,
    Marchands,
    MarchandsRetour,
    Barbare,

    Bois,
    Argile,
    Fer,
    Villageois,

    CoffrePlein,
    CoffreVide,

    Defense,
    Soutien,
    SoutienAttaque,
    SoutienEpee,
    BouclierEpee,

    Info,
    Aller,
    Retour,
    LameSang,
    Crane,
    Drapeau,
	},
  props: {
    icone: String,
    largeur: String,
    color: {
      type: String,
      default: '#DCC8AA'
    }
  },
  computed: {
    style() {
      return `width: ${this.largeur}; height: ${this.largeur}`
    }
  }
}