import store from '@/store'
import Message from './Message'

export default class Conversation {
	constructor(contenu) {
		this.id = contenu.id
		this.utilisateurs = contenu.utilisateurs.map(id => store.state.monde.utilisateurs.find(utilisateur => utilisateur.id == id))
		this.messages = contenu.messages.map(contenu => new Message(contenu))
		this.idDernierMessageVu = contenu.idDernierMessageVu
	}

	get dernierMessage() {
		if (this.messages) {
			return this.messages[this.messages.length - 1]
		}
		return null
	}

	get messagesNonVus() {
		return this.messages.filter(m => m.id > this.idDernierMessageVu)
	}

	ajouterMessage(contenu) {
		this.messages.push(new Message(contenu))
	}
}