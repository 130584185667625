import store from '@/store'

export default class EchangeRessources {
    constructor(contenu) {
        this.id = contenu.id
        this.tourDepart = contenu.tourDepart
        this.tourArrivee = contenu.tourArrivee
        this.tourRetour = contenu.tourRetour
        this.ressourceEnvoyee = contenu.ressourceEnvoyee
        this.ressourceRecue = contenu.ressourceRecue
        this.quantiteEnvoyee = contenu.quantiteEnvoyee
        this.quantiteRecue = contenu.quantiteRecue
        this.marchands = contenu.marchands
        this.village = store.state.villages.find(v => v.id == contenu.idVillage)
    }
}