import Icone from '@/components/blocs/Icone/Icone.vue'

export default {
	components: {
		Icone
	},
	props: {
		to: String,
		sansLibelle: Boolean,
		icone: String,
		submit: Boolean,
		disabled: Boolean,
		loading: Boolean,
		tab: Boolean,
		block: Boolean,
		largeur: Number,
		xl: Boolean,
		color: String,
	},
	computed: {
		classe() {
			let classe = 'bouton'
			if (this.tab) classe += '-tab'
			else if (this.xl) classe += '-xl'
			else if (this.sansLibelle) classe += '-icone'
			
			if (this.block) classe += ' block'
			if (this.disabled) classe += ' disabled'
			if (this.color) classe += ' button-' + this.color

			return classe
		}
	}
}