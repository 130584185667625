import PageAccueil from '../components/pages/accueil/PageAccueil/PageAccueil.vue'

export default {
	path: '/',
	name: 'accueil',
	meta: {
		titre: 'Accueil',
		sansConnexion: true
	},
	component: PageAccueil
}