import Icone from '@/components/blocs/Icone/Icone.vue'

export default {
    components: {
        Icone
    },
    props: {
        color: String,
        icon: String,
        value: Number
    },
    computed: {
        roundedValue() {
            return Math.round(this.value)
        },
        iconColor() {
            switch (this.color) {
                case 'red':
                    return '#E13729'
                case 'green':
                    return '#179850'
                case 'blue':
                    return '#086ce0'
                case 'yellow':
                    return '#ffbe04'
                default:
                    return '#838A78'
            }
        }
    }
}