const getVillagesDuMonde = (idMonde) => {
	return document.axios.get('villages/monde/' + idMonde)
}

const renommer = (idVillage, donnees) => {
	return document.axios.post('villages/' + idVillage + '/renommer', donnees)
}

export default {
	getVillagesDuMonde,
	renommer
}
