import { unites } from 'friendswars.shared'
import HautDePageInterface from '@/components/blocs/HautDePageInterface/HautDePageInterface.vue'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import AfficheurPrix from '@/components/blocs/AfficheurPrix/AfficheurPrix.vue'
import BarProgression from '@/components/blocs/BarProgression/BarProgression.vue'

export default {
	components: {
        HautDePageInterface,
		BlocAction,
		AfficheurPrix,
		BarProgression
	},
	props: {
		nomUnite: String
	},
	computed: {
		unite() {
			if (this.nomUnite) {
				return unites[this.nomUnite]
			}
		}
	}
}