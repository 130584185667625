const inscription = (donnees) => {
	return document.axios.post('inscription', donnees)
}

const connexion = (donnees) => {
	return document.axios.post('connexion', donnees)
}

const connexionParJeton = (jeton) => {
	return document.axios.post('connexion/' + jeton)
}

const motDePasseOublie = (donnees) => {
	return document.axios.post('mot-de-passe-oublie', donnees)
}

const recupererReinitialisationMotDePasse = (jeton) => {
	return document.axios.get('reinitialisation-mot-de-passe/' + jeton)
}

const reinitialisationMotDePasse = (jeton, donnees) => {
	return document.axios.post('reinitialisation-mot-de-passe/' + jeton, donnees)
}

const validationCompte = (jeton) => {
	return document.axios.post('/compte/validation/' + jeton)
}

export default {
	inscription,
	connexion,
	connexionParJeton,
	motDePasseOublie,
	recupererReinitialisationMotDePasse,
	reinitialisationMotDePasse,
	validationCompte
}