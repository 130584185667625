export default class Equipe {
	constructor(contenu) {
		this.id = contenu.id
		this.nom = contenu.nom
		this.rang = contenu.rang
        this.utilisateurs = []
        this.ressourcesPillees = contenu.ressourcesPillees
        this.soldatsRecrutes = contenu.soldatsRecrutes
        this.unitesBarbaresTuees = contenu.unitesBarbaresTuees
        this.unitesJoueursTuees = contenu.unitesJoueursTuees
        this.villagesCaptures = contenu.villagesCaptures
        this.villagesPerdus = contenu.villagesPerdus
        this.capitalesCapturees = contenu.capitalesCapturees
        this.capitalesPerdues = contenu.capitalesPerdues
	}
}