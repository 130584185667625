import ConteneurBatiment from '@/components/blocs/ConteneurBatiment/ConteneurBatiment.vue'

export default {
    components: {
        ConteneurBatiment
    },
    data () {
        return {
            
        }
    },
    computed: {
        village() {
            return this.$store.state.village
        },
        pourcentageActuel() {
            return this.village.muraille.niveau * 5
        },
        prochainPourcentage() {
            return this.pourcentageActuel + 5
        }
    }
}