import VueApexCharts from 'vue-apexcharts'
import Village from '@/classes/Village'

export default {
    components: {
		apexchart: VueApexCharts
	},
	data() {
        return {
            maxHauteur: null,
            currentNombreLignes: this.nombreLignes,
            currentNombreColonnes: this.nombreColonnes,
            nombreLignesEspace: 0,
            nombreColonnesEspace: 0,
            carte: null,
            coordonneesBloquees: [],
            deplacement: false
        }
    },
    props: {
        nombreLignes: Number,
        nombreColonnes: Number,
        coordonnees: Array,
        villages: Array,
        villageCible: Village,
        lobby: Boolean,
        bloquerVillage: Boolean,
        zoom: Number,
        carre: Boolean,
        nonCliquable: Boolean
    },
    computed: {
        largeurLoyaute () {
            switch (this.zoom) {
                case 4:
                    return 80
                case 3:
                    return 70
                case 2:
                    return 60
                case 1:
                    return 50
                case 0:
                    return 0
            }
        },
        largeurTuile () {
            switch (this.zoom) {
                case 4:
                    return 120
                case 3:
                    return 80
                case 2:
                    return 60
                case 1:
                    return 40
                case 0:
                    return 20
            }
        },
        hauteurTuile () {
            return this.largeurTuile * 3/4
        },
        scrollableX () {
            return this.nombreColonnesEspace == 1
        },
        scrollableY () {
            return this.nombreLignesEspace == 2
        },
        decalage () {
            return this.currentNombreLignes % 2 == 0
        }
    },
    methods: {
        genererCarte() {
            this.carte = {}
            this.currentNombreLignes = this.nombreLignes
            this.currentNombreColonnes = this.nombreColonnes
            const coordonnees = this.coordonnees ? Object.fromEntries(new Map(this.coordonnees.map(c => [c.ligne + '-' + c.colonne, c.type]))) : null
            const villages = this.villages ? Object.fromEntries(new Map(this.villages.map(v => [v.ligne + '-' + v.colonne, v]))) : null

            for (let numeroLigne = 1; numeroLigne <= this.currentNombreLignes; numeroLigne++) {
                const ligne = {}
                
                for (let numeroColonne = 1; numeroColonne <= this.nombreColonnes; numeroColonne++) {
                    let tuile = {
                        type: null
                    }
                    if (coordonnees) {
                        tuile.type = coordonnees[numeroLigne + '-' + numeroColonne]
                    } else if (
                        numeroColonne == 1 ||
                        (numeroColonne == this.nombreColonnes && numeroLigne % 2 == 1) ||
                        numeroLigne == 1 ||
                        numeroLigne == this.currentNombreLignes
                    ) {
                        tuile.type = 'ocean'
                    } else {
                        tuile.type = 'plaine'
                    }

                    if (villages && villages[numeroLigne + '-' + numeroColonne]) {
                        tuile.village = villages[numeroLigne + '-' + numeroColonne]
                    }
                    ligne[numeroColonne] = tuile
                }
                if (numeroLigne % 2 == 0) {
                    ligne[this.nombreColonnes + 1] = {
                        type: coordonnees ? coordonnees[numeroLigne + '-' + (this.nombreColonnes + 1)] : 'ocean'
                    }
                }
                this.carte[numeroLigne] = ligne
            }

            this.$nextTick(() => {
                this.setCoordonneesBloquees()
                this.redimensionnement()
            })
		},
        onDragStart () {
            if (this.$timer) {
                clearTimeout(this.$timer)
                this.$timer = null
            }
            this.$timer = setTimeout(() => {
                this.deplacement = true
            }, 100)
        },
        onDragEnd () {
            if (this.$timer) {
                clearTimeout(this.$timer)
                this.$timer = null
            }
            this.$timer = setTimeout(() => {
                this.deplacement = false
            }, 500)
        },
        estBloquee (numeroLigne, numeroColonne) {
            return this.bloquerVillage && this.coordonneesBloquees && this.coordonneesBloquees.some(c => c.ligne == numeroLigne && c.colonne == numeroColonne)
        },
        preparerGenerationCarte (duree = 200) {
            if (this.$generationTimer) clearTimeout(this.$generationTimer)

            this.$generationTimer = setTimeout(() => {
                this.genererCarte(duree != 0)
            }, duree)
        },
        calculerDistance (village1, village2) {
            const cote1 = village2.ligne - village1.ligne
            const cote2 = village2.colonne - village1.colonne
            const distance = Math.sqrt(cote1 ** 2 + cote2 ** 2)
            return Math.round(distance * 100) / 100
        },
        preparerRedimensionnement() {
            if (this.$redimensionnementTimer) clearTimeout(this.$redimensionnementTimer)

            this.$redimensionnementTimer = setTimeout(() => {
                this.redimensionnement()
            }, 100)
        },
        redimensionnement () {
            const nombreLignesEspace = Math.ceil((Math.ceil(this.$refs.conteneur.clientHeight / this.hauteurTuile) - this.currentNombreLignes) / 2)
            const nombreColonnesEspace = Math.ceil((Math.ceil(this.$refs.conteneur.clientWidth / this.largeurTuile) - this.currentNombreColonnes) / 2)
            this.nombreLignesEspace = nombreLignesEspace >= 1 ? nombreLignesEspace : 1
            this.nombreColonnesEspace = nombreColonnesEspace >= 1 ? nombreColonnesEspace : 1
            
            if (this.nombreLignesEspace % 2 == 1) {
                this.nombreLignesEspace++
            }
            
            this.centrerCarte()
        },
        onClicTuile (tuile) {
            if (!this.deplacement) {
                this.$emit('change', tuile)
                this.setCoordonneesBloquees()
            }
        },
        clear () {
            if (!this.deplacement) {
                this.$emit('clear')
            }
        },
        setMaxHauteur () {
            this.maxHauteur = this.carre && this.$refs.conteneur ? this.$refs.conteneur.clientWidth : null
        },
		centrerCarte() {
			setTimeout(() => {
                if (this.$refs.conteneur && this.$refs.table) {
                    const width = this.$refs.conteneur.clientWidth
                    const height = this.$refs.conteneur.clientHeight
                    const village = this.villageCible || this.$store.state.village

                    if (village && this.scrollableX) {
                        this.$refs.conteneur.scrollLeft = village.colonne * this.largeurTuile - width / 2
                    } else {
                        this.$refs.conteneur.scrollLeft = (this.$refs.table.clientWidth - width) / 2 - (this.largeurTuile / 2)
                    }
                    
                    if (village && this.scrollableY) {
                        this.$refs.conteneur.scrollTop = village.ligne * this.hauteurTuile - height / 2
                    } else {
                        this.$refs.conteneur.scrollTop = (this.$refs.table.clientHeight - height) / 2
                    }
                }
			}, 10)
		},
        getCoordonneesMappees () {
            return Object.entries(this.carte).map(([ligne, colonnes]) =>
                Object.entries(colonnes).map(([colonne, tuile]) => {
                    return {
                        ligne: Number(ligne),
                        colonne: Number(colonne),
                        type: tuile.type
                    }
                })
            ).flat()
        },
        setCoordonneesBloquees () {
            const villages = this.getCoordonneesMappees().filter(c => c.type == 'chateauTerre' || c.type == 'villageTerre')

            this.coordonneesBloquees = villages
                .concat(villages.map(v => {
                    return {
                        ligne: v.ligne + 1,
                        colonne: v.colonne
                    }
                }))
                .concat(villages.map(v => {
                    return {
                        ligne: v.ligne + 1,
                        colonne: v.colonne + (v.ligne % 2 == 1 ? 1 : -1)
                    }
                }))
                .concat(villages.map(v => {
                    return {
                        ligne: v.ligne - 1,
                        colonne: v.colonne
                    }
                }))
                .concat(villages.map(v => {
                    return {
                        ligne: v.ligne - 1,
                        colonne: v.colonne + (v.ligne % 2 == 1 ? 1 : -1)
                    }
                }))
                .concat(villages.map(v => {
                    return {
                        ligne: v.ligne,
                        colonne: v.colonne + 1
                    }
                }))
                .concat(villages.map(v => {
                    return {
                        ligne: v.ligne,
                        colonne: v.colonne - 1
                    }
                }))
        }
    },
    watch: {
        zoom () {
            this.redimensionnement()
        },
        nombreLignes () {
            this.preparerGenerationCarte()
        },
        nombreColonnes () {
            this.preparerGenerationCarte()
        },
        coordonnees () {
            this.preparerGenerationCarte(0)
        },
        villages () {
            this.preparerGenerationCarte(0)
        }
    },
    mounted () {
        this.setMaxHauteur()
        this.genererCarte()

		window.addEventListener('resize', this.preparerRedimensionnement)
		window.visualViewport.addEventListener('resize', this.preparerRedimensionnement)
    },
    beforeDestroy () {
		window.removeEventListener('resize', this.preparerRedimensionnement)
		window.visualViewport.removeEventListener('resize', this.preparerRedimensionnement)
    }
}