import { batiments } from 'friendswars.shared'
import Batiment from './Batiment.js'

export default class Entrepot extends Batiment {
	constructor(niveau) {
		super(batiments.entrepot, niveau)

		this.limiteBase = batiments.entrepot.limiteBase
		this.ratioLimite = batiments.entrepot.ratioLimite
	}

	getLimite() {
		return this.calculerMontant(this.limiteBase, this.ratioLimite, this.niveau)
	}

	getProchaineLimite() {
		return this.calculerMontant(this.limiteBase, this.ratioLimite, this.niveau + 1)
	}
}