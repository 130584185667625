import PageErreur404 from '../components/pages/erreur/PageErreur404/PageErreur404.vue'

export default {
	erreur404: {
		path: '*',
		name: 'erreur.404',
		meta: {
			titre: 'La page n\'existe pas'
		},
		component: PageErreur404
	}
}