import Icone from '@/components/blocs/Icone/Icone.vue'

export default {
	components: {
		Icone
	},
	computed: {
		monde() {
			return this.$store.state.monde
		},
		village() {
			return this.$store.state.village
		},
		villagesDuJoueur() {
			return this.$store.state.villagesDuJoueur
		},
		autresVillagesDuJoueur() {
			return this.villagesDuJoueur.filter(v => v.id != this.village.id)
		},
		tourTermine() {
			return this.$store.state.tourTermine
		},
		limiteEntrepot() {
			return this.village.entrepot.getLimite()
		},
		pourcentageBois() {
			return this.village.bois / this.limiteEntrepot * 100
		},
		pourcentageArgile() {
			return this.village.argile / this.limiteEntrepot * 100
		},
		pourcentageFer() {
			return this.village.fer / this.limiteEntrepot * 100
		},
		pourcentageVillageois() {
			return this.village.provisions / this.village.ferme.getLimite() * 100
		},
		couleur() {
			return this.monde.utilisateurs.find(utilisateur => this.village.idUtilisateur == utilisateur.id).couleur
		},
		hauteurRessourceBarre() {
			return this.$vuetify.breakpoint.width > 450 ? 8 : 4
		}
	},
    methods: {
		changerVillage(village) {
			this.$store.state.village = village
			
			if (this.$route.name != 'jeu') {
				this.$router.push({
					name: 'jeu'
				})
			}
        }
    }
}