import HautDePageInterface from '@/components/blocs/HautDePageInterface/HautDePageInterface.vue'
import Monde from '@/classes/Monde';
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Bouton from '@/components/blocs/Bouton/Bouton.vue';

export default {
	components: {
        HautDePageInterface,
        BlocAction,
        Bouton
    },
    data() {
		return {
			code: null,
			erreurs: {}
		}
    },
    methods: {
        valider() {
            const donnees = {
                code: this.code
            }

            this.$serveur.mondes.rejoindre(donnees).then((reponse)=> {
                this.$store.state.monde = new Monde(reponse.data.monde) 
                this.$router.push({
                    name: 'lobby'
                })
            }, (erreur) => {
				if (erreur.response.status == 422) {
					this.erreurs = erreur.response.data.erreurs 
                }
            })
        }
    }
}      