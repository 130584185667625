import store from '@/store'

export default class Evenement {
	constructor(contenu) {
		this.id = contenu.id
		this.tour = contenu.tour
		this.type = contenu.type
		this.description = contenu.description.replace(/\n/g, '<br>')
        
        if (contenu.idUtilisateur) {
            this.utilisateur = store.state.monde.utilisateurs.find(u => u.id == contenu.idUtilisateur)
        } else {
            this.utilisateur = null
        }
        
        if (contenu.idEquipe) {
            this.equipe = store.state.monde.equipes.find(u => u.id == contenu.idEquipe)
        } else {
            this.equipe = null
        }
        
        if (contenu.idVillage) {
            this.village = store.state.villages.find(v => v.id == contenu.idVillage)
        } else {
            this.village = null
        }

		this.couleur = this.utilisateur ? this.utilisateur.couleur : this.equipe ? this.equipe.utilisateurs[0].couleur : 'grey darken-4'

        switch (this.type) {
            case 'creation-monde':
                this.icone = 'mdi-earth'
                break;
            case 'abandon':
            case 'defaite':
                this.icone = 'mdi-skull-crossbones'
                break;
            case 'village-conquis':
            case 'capitale-conquise':
                this.icone = 'mdi-plus-one'
                break;
            case 'victoire':
                this.icone = 'mdi-crown'
                break;
            case 'premier-sang':
                this.icone = 'mdi-water'
                break;
        }
    }
}