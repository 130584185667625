export default {
	utilisateur: null,
	jeton: null,
	monde: null,
	villages: null,
	village: null,
	villagesDuJoueur: [],
	joueursEnAttente: [],
	tourTermine: false,
	rapports: null,
	afficherInterface: true,
	rapportSelectionne: null,
	evenements: null,
	ordres: null,
	conversations: null,
	zoom: 3,
	carte: null
}
