import HautDePageInterface from '@/components/blocs/HautDePageInterface/HautDePageInterface.vue'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Bouton from '@/components/blocs/Bouton/Bouton.vue'
import Avatar from '@/components/blocs/Avatar/Avatar.vue'
import Monde from '@/classes/Monde'

export default {
	components: {
		HautDePageInterface,
		BlocAction,
		Bouton,
		Avatar
	},
	data() {
		return {
			mondesTermines: null,
			mondesCommences: null,
			mondesEnAttente: null
		}
	},
	methods: {
        getListe() {
			this.$serveur.mondes.getListeUtilisateur().then(reponse => {
				const mondes = reponse.data.mondes.map(contenu => new Monde(contenu))
	
				this.mondesTermines = mondes.filter(monde => monde.dateFin != null)
				this.mondesCommences = mondes.filter(monde => monde.dateFin == null && monde.dateLancement != null)
				this.mondesEnAttente = mondes.filter(monde => monde.dateFin == null && monde.dateLancement == null)
			})
        },
		selectionnerMonde(monde) {
			this.$store.state.monde = monde
			if (monde.dateLancement) {
				this.$router.push({
					name: 'jeu'
				})
			} else {
				this.$router.push({
					name: 'lobby'
				})
			}
		}
	},
	mounted() {
        this.$store.state.carte = null
		this.getListe()
	}
}