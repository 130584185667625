import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Equipe from '@/classes/Equipe'

export default {
	components: {
		BlocAction
	},
    data () {
        return {
            nom: this.equipe ? this.equipe.nom : null,
            chargement: false,
			erreurs: {}
        }
    },
    props: {
        equipe: Equipe
    },
    computed: {
		monde() {
			return this.$store.state.monde
		},
        utilisateur () {
            return this.monde.utilisateurs.find(u => u.id == this.$store.state.utilisateur.id)
        }
    },
    methods: {
        valider () {
            this.chargement = true
            const donnees = {
                nom: this.nom
            }

            this.$serveur.equipes.modifier(this.monde.id, this.equipe.id, donnees).then(() => {
                this.$emit('valider', this.nom)
            }, erreur => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				} else if (erreur.response.status == 422) {
					this.erreurs = erreur.response.data.erreurs
				}
			}).finally(() => {
                this.chargement = false
            })
        }
    },
    mounted () {
        setTimeout(() => {
            this.$refs.input.focus()
        }, 200)
    }
}