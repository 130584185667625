const creerConversation = (idMonde, donnees) => {
	return document.axios.post('conversations/' + idMonde, donnees)
}

const envoyerMessage = (idConversation, donnees) => {
	return document.axios.post('messages/' + idConversation, donnees)
}

const getListeMessages = (idMonde) => {
	return document.axios.get('messages/' + idMonde)
}

const marquerMessageCommeVu = (id) => {
	return document.axios.post('messages/' + id + '/vu')
}

export default {
	creerConversation,
	envoyerMessage,
	getListeMessages,
	marquerMessageCommeVu
}