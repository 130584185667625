import VueApexCharts from 'vue-apexcharts'
import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import Avatar from '@/components/blocs/Avatar/Avatar.vue'

export default {
	components: {
		apexchart: VueApexCharts,
		BlocAction,
		Avatar
	},
	data() {
		return {
			tab: 0,
			utilisateurs: [],
			equipes: [],
			valeursScore: null,
			optionsScore: null,
			totaux: null
		}
	},
	computed: {
		monde() {
			return this.$store.state.monde
		},
		evenements() {
			return this.$store.state.evenements
		},
		tour() {
			return this.monde.tour
		}
	},
	methods: {
		setRecap() {
			this.$serveur.stats.getListe(this.monde.id).then(reponse => {
				this.totaux = reponse.data.totaux
				const statsGroupees = reponse.data.stats.groupBy('idUtilisateur')
				this.valeursScore = this.monde.utilisateurs.map(utilisateur => {
					const stats = statsGroupees[utilisateur.id]
					return {
						name: utilisateur.nom + ' ',
						data: stats.map(s => s.score)
					}
				})

				this.optionsScore = {
					theme: {
						mode: 'dark'
					},
					chart: {
						type: 'area',
						zoom: {
							enabled: false
						},
						toolbar: {
							show: false
						},
						animations: {
							enabled: false
						}
					},
					legend: {
						show: false
					},
					markers: {
						size: 0,
						strokeWidth: 0
					},
					stroke: {
						curve: 'smooth',
						width: 5
					},
					dataLabels: {
						enabled: false
					},
					xaxis: {
						categories: Object.values(statsGroupees)[0].map((stat, index) => 'Tour ' + (index + 1)),
						labels: {
							show: false
						},
						axisTicks: {
							show: false
						}
					},
					colors: this.monde.utilisateurs.map(u => u.couleur)
				}
			})
		},
		marquerDernierEvenementCommeVu() {
			if (this.tab == (this.monde.dateFin ? 2 : 1)) {
				this.$serveur.evenements.marquerDernierEvenementVu(this.monde.id).then(reponse => {
					this.monde.utilisateur.idDernierEvenement = reponse.data.id
				})
			}
		}
	},
	watch: {
		tour () {
			this.marquerDernierEvenementCommeVu()
		},
		tab () {
			this.marquerDernierEvenementCommeVu()
		}
	},
	mounted() {
		if (this.monde.dateFin) {
			this.setRecap()
		}

		const equipes = this.monde.equipes.map(equipe => {
			equipe.villages = equipe.utilisateurs
				.map(u => this.$store.state.villages.filter(v => v.idUtilisateur == u.id))
				.reduce((villages, villagesUtilisateur) => villages.concat(villagesUtilisateur), [])

			equipe.points = equipe.villages.reduce((points, village) => points + village.points, 0)
			equipe.score = equipe.utilisateurs.reduce((score, utilisateur) => score + utilisateur.score, 0) 
			return equipe
		})

		this.equipes = equipes.map(equipe => {
			return {
				id: equipe.id,
				nom: equipe.nom,
				utilisateurs: equipe.utilisateurs,
				villages: equipe.villages,
				score: equipe.score,
			}
		}).sort((a, b) => {
			if (a.villages.length > b.villages.length) {
				return -1
			} else if (a.villages.length < b.villages.length) {
				return 1
			} else if (a.score > b.score) {
				return -1
			} else if (a.score < b.score) {
				return 1
			} else {
				return 0
			}
		})
	}
}