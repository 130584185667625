import BlocAction from '@/components/blocs/BlocAction/BlocAction.vue'
import SelectionneurUnites from '@/components/blocs/SelectionneurUnites/SelectionneurUnites.vue'
import Village from '@/classes/Village'

export default {
    components: {
        BlocAction,
        SelectionneurUnites
    },
    data () {
        return {
            chargement: false,
            unites: {}
        }
    },
    props: {
        villageCible: Village,
        distance: Number,
    },
    computed: {
        village() {
            return this.$store.state.village
        },
        nombreTours() {
            const nombreToursInfanterie = Math.ceil(this.distance / 2)
            let nombreTours = Math.ceil(this.distance / 3)
            if (nombreTours == nombreToursInfanterie && nombreTours > 1) {
                nombreTours--
            }
            
            return nombreTours
        }
    },
    methods: {
        envoyer() {
            this.chargement = true
			const donnees = {
				espions: this.unites.espions
            }
            
			this.$serveur.ordres.espionnerVillage(this.village.id, this.villageCible.id, donnees).then(reponse => {
				this.$store.commit('actualiserVillage', reponse.data.village)
				this.$store.commit('actualiserOrdres', reponse.data.ordres)
                this.$router.back()
			}, (erreur) => {
				if (erreur.response.status == 400) {
					this.$root.$children[0].ajouterNotification(erreur.response.data.message, 'error')
				}
			}).finally(() => this.chargement = false)
        }
    }
}