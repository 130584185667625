import ConteneurBatiment from '@/components/blocs/ConteneurBatiment/ConteneurBatiment.vue'
import { batiments } from 'friendswars.shared'

export default {
    components: {
        ConteneurBatiment
    },
    data () {
        return {
            batiments: Object.values(batiments).filter(batiment => batiment.niveauChateauRequis > this.$store.state.village.chateau.niveau)
        }
    }
}