const selectionnerAvatar = (donnees) => {
	return document.axios.put('/compte/avatar', donnees)
}

const modifierCouleurDefaut = (donnees) => {
	return document.axios.put('/compte/couleur', donnees)
}

const envoiEmailValidation = () => {
	return document.axios.post('/compte/email-verification')
}

const modifierParametreNotification = (type) => {
	return document.axios.put('/compte/notifications/' + type)
}

export default {
    selectionnerAvatar,
    modifierCouleurDefaut,
    envoiEmailValidation,
    modifierParametreNotification
}