const urlBase64ToUint8Array = (base64String) => {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

async function abonnerNotifications() {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.ready.then(register => {
			if (Notification.permission != 'denied') {
				register.pushManager.subscribe({
					userVisibleOnly: true,
					applicationServerKey: urlBase64ToUint8Array(process.env.VUE_APP_NOTIFICATION_CLE_PUBLIQUE)
				}).then(subscription => {
					document.axios.post('/compte/notifications/subscribe', subscription)
				}, async () => {
					const subscription = await register.pushManager.getSubscription()
					
					if (subscription) {
						subscription.unsubscribe()
					}
				})
			}
		})
	} else {
		console.log('Service workers are not supported in this browser')
	}
}

export {
	abonnerNotifications
}